import { Component, OnChanges, EventEmitter, ElementRef, Input, Output, ViewChild, HostListener } from '@angular/core';
import { ClientHttpsService } from '../../../../services/client-https.service';
import { GRAPH_HEIGHTS, MILLI_TO_DAY, COLOR_STYLES, CAL_PER_NUT_GRAM } from '../config';
import { Observable } from 'rxjs';
import * as d3 from 'd3';

interface Resizing {
  isResizing: boolean;
  currentInnerWidth?: number;
  timeOut?: NodeJS.Timer;
}

@Component({
  selector: 'app-duration-bar-graph',
  templateUrl: './duration-bar-graph.component.html',
  styleUrls: ['./duration-bar-graph.component.css', '../patient.component.css']
})
export class DurationBarGraphComponent implements OnChanges {
  @Input() baseDate: Date;
  @Input() patientId: string;
  @Input() isDataLoaded: boolean;
  @Output() baseDateChangedEvent = new EventEmitter<Date>();
  @ViewChild('graph', { static: true }) graphWidth: ElementRef;

  public resizing: Resizing;
  public timeRange = 7;
  public startDate: Date;
  public endDate: Date;
  public isLoading: boolean;
  private baseY = GRAPH_HEIGHTS.main - 50;
  private maxCalY = 500;
  private maxDurY = 30;
  private foods = [];
  private exercises = [];

  set graphTime(date: string) {
    const fDate = new Date(date).setHours(0, 0, 0, 0);
    this.startDate = new Date(fDate - (this.timeRange * MILLI_TO_DAY));
    this.endDate = new Date(fDate);
    this.drawGraph();
  }

  get graphTime() {
    return this.endDate.toISOString();
  }

  public get isLoadingBinary() {
    if (this.isLoading) {
      return 0;
    } else {
      return 1;
    }
  }

  constructor(public client: ClientHttpsService) { }

  ngOnChanges() {
    this.resizing = { isResizing: false };
    this.isLoading = true;
    this.graphTime = this.baseDate.toISOString();

    d3.select('svg#duration-bar-graph')
      .attr('height', GRAPH_HEIGHTS.main)
      .attr('width', this.graphWidth.nativeElement.offsetWidth);

    if (this.isDataLoaded) {
      this.dataObservable.subscribe(data => {
        this.formatData(data);
        this.drawGraph();
      });
    }
  }

  private get dataObservable(): Observable<any> {
    return this.client.getClientNutTimeframeData(
      this.client.orgIdDefault,
      this.patientId,
      this.startDate.toISOString(),
      this.endDate.toISOString()
    );
  }

  private formatData(data: any): void {
    data.forEach((d: any) => {
      d.time = new Date(new Date(d.time).setHours(0, 0, 0, 0));

      if (d.type === 'food') {
        let exists = false;
        this.foods.forEach((f: any) => {
          if (f.time.toISOString() === d.time.toISOString()) {
            Object.keys(f.nutritionFacts).forEach((n) => {
              f.nutritionFacts[n] = +f.nutritionFacts[n] + +d.nutritionFacts[n];
            });

            exists = true;
          }
        });

        if (!exists) {
          Object.keys(d.nutritionFacts).forEach((n) => {
            d.nutritionFacts[n] = +d.nutritionFacts[n];
          });

          this.foods.push(d);
        }
      } else if (d.type === 'exercise') {
        let exists = false;
        this.exercises.forEach((e: any) => {
          if (e.time.toISOString() === d.time.toISOString()) {
            e.duration = +e.duration + +d.duration;
            exists = true;
          }
        });

        if (!exists) {
          d.duration = +d.duration;
          this.exercises.push(d);
        }
      }
    });

    const maxC = Math.round(d3.max(this.foods, (d: any) => d.nutritionFacts.calories) + 10);
    const maxD = Math.round(d3.max(this.exercises, (d: any) => d.duration) + 10);

    this.maxCalY = maxC > this.maxCalY ? maxC : this.maxCalY;
    this.maxDurY = maxD > this.maxDurY ? maxD : this.maxDurY;
  }

  private getCarbHeight(food: any, yScale: any): number {
    return this.baseY - yScale(food.nutritionFacts.carbohydrate * CAL_PER_NUT_GRAM.carbohydrate);
  }

  private getFatHeight(food: any, yScale: any): number {
    return this.baseY - yScale(food.nutritionFacts.fat * CAL_PER_NUT_GRAM.fat);
  }

  private getProteinHeight(food: any, yScale: any): number {
    return this.baseY - yScale(food.nutritionFacts.protein * CAL_PER_NUT_GRAM.protein);
  }

  private getExerciseHeight(exercise: any, yScale: any): number {
    return this.baseY - yScale(exercise.duration);
  }

  private getCarbY(food: any, yScale: any): number {
    return this.baseY - (this.getFatHeight(food, yScale)
      + this.getProteinHeight(food, yScale)
      + this.getCarbHeight(food, yScale));
  }

  private getFatY(food: any, yScale: any): number {
    return this.baseY - (this.getFatHeight(food, yScale)
      + this.getProteinHeight(food, yScale));
  }

  private getProteinY(food: any, yScale: any): number {
    return this.baseY - this.getProteinHeight(food, yScale);
  }

  private getExerciseY(exercise: any, yScale: any): number {
    return this.baseY - this.getExerciseHeight(exercise, yScale);
  }

  /* ===== DRAW SPAGHETTI GRAPH ===== */
  public drawGraph() {
    const svg = d3.select('svg#duration-bar-graph');

    this.isLoading = true;

    /* -- remove preexisting elements -- */
    svg.selectAll('g.primary-axis')
      .remove();

    svg.selectAll('g.target-axis')
      .remove();

    const xScale = d3.scaleTime()
      .domain([this.startDate, this.endDate]) // Time frame of one day
      .range([100, this.graphWidth.nativeElement.offsetWidth - 100]);

    const yCalScale = d3.scaleLinear()
      .domain([0, this.maxCalY])
      .range([this.baseY, 30]);

    const yExerScale = d3.scaleLinear()
      .domain([0, this.maxDurY])
      .range([this.baseY, 30]);

    const xAxis = d3.axisBottom(xScale)
      .ticks(5)
      .tickFormat((d: Date) => `${d.getMonth()}/${d.getDate()}`);

    const yAxis = d3.axisLeft(yCalScale)
      .ticks(7);

    /* -- Draws x-axis ticks -- */
    svg.append('g')
      .attr('transform', `translate(0, ${GRAPH_HEIGHTS.main - 35})`)
      .classed('primary-axis', true)
      .classed('x-axis', true)
      .call(xAxis);

    /* -- Draw y-axis ticks -- */
    svg.append('g')
      .attr('transform', 'translate(75, 0)')
      .classed('primary-axis', true)
      .classed('y-axis', true)
      .call(yAxis);

    /* -- Format Axes-- */
    svg.selectAll('.primary-axis')
      .selectAll('path')
      .remove();

    svg.selectAll('.primary-axis')
      .selectAll('g')
      .selectAll('line')
      .remove();

    svg.selectAll('.primary-axis')
      .selectAll('g')
      .selectAll('text')
      .attr('fill', COLOR_STYLES.graph)
      .attr('dy', '.2em')
      .attr('style', 'font-size: 1.4em;');

    svg.selectAll('.target-axis') // Target Axes
      .selectAll('path')
      .remove();

    svg.selectAll('.target-axis') // Target Axes
      .selectAll('g')
      .selectAll('line')
      .remove();

    svg.selectAll('.target-axis') // Target Axes
      .selectAll('g')
      .selectAll('text')
      .attr('fill', COLOR_STYLES.bloodGlucoseHighlightText)
      .attr('dy', '.2em')
      .attr('style', 'font-size: 1.4em;');

    /* -- Horizontal graph lines -- */
    svg.selectAll('g.y-axis g.tick')
      .append('line')
      .classed('grid-line', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
      .attr('y2', 0)
      .attr('stroke', COLOR_STYLES.graph);

    svg.selectAll('g.target-axis g.tick') // Target grid lines
      .append('line')
      .classed('grid-target-line', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
      .attr('y2', 0)
      .attr('stroke', COLOR_STYLES.bloodGlucoseHighlightText)
      .attr('stroke-dasharray', '10,10');

    this.drawBars(svg, yCalScale, yExerScale, xScale);

    svg.selectAll('rect')
      .raise();

    this.isLoading = false;
  }

  private drawBars(svg, yCalScale, yExerScale, xScale): void {
    svg.selectAll('rect.carbohydrate')
      .data(this.foods)
      .join('rect')
      .classed('carbohydrate', true)
      .classed('nut-bar', true)
      .attr('fill', COLOR_STYLES.food.carbohydrate)
      .attr('transform',
        (d: any) => `translate(${xScale(new Date(d.time)) - 6},
          ${this.getCarbY(d, yCalScale)})`)
      .attr('width', '6px')
      .attr('height', (d: any) => this.getCarbHeight(d, yCalScale));

    svg.selectAll('rect.fat')
      .data(this.foods)
      .join('rect')
      .classed('fat', true)
      .classed('nut-bar', true)
      .attr('fill', COLOR_STYLES.food.fat)
      .attr('transform',
        (d: any) => `translate(${xScale(new Date(d.time)) - 6},
          ${this.getFatY(d, yCalScale)})`)
      .attr('width', '6px')
      .attr('height', (d: any) => this.getFatHeight(d, yCalScale));

    svg.selectAll('rect.protein')
      .data(this.foods)
      .join('rect')
      .classed('protein', true)
      .classed('nut-bar', true)
      .attr('fill', COLOR_STYLES.food.protein)
      .attr('transform',
        (d: any) => `translate(${xScale(new Date(d.time)) - 6},
          ${this.getProteinY(d, yCalScale)})`)
      .attr('width', '6px')
      .attr('height', (d: any) => this.getProteinHeight(d, yCalScale));

    svg.selectAll('rect.exercise')
      .data(this.exercises)
      .join('rect')
      .classed('exercise', true)
      .attr('fill', 'orange')
      .attr('transform',
        (d: any) => `translate(${xScale(new Date(d.time))},
          ${this.getExerciseY(d, yExerScale)})`)
      .attr('width', '6px')
      .attr('height', (d: any) => this.getExerciseHeight(d, yExerScale));
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    if (this.resizing.currentInnerWidth !== window.innerWidth) {
      this.resizing.isResizing = true;
      if (this.resizing.hasOwnProperty('timeOut')) {
        clearTimeout(this.resizing.timeOut);
      }

      // Create timeout for 300ms
      this.resizing.timeOut = setTimeout(() => {
        if (this.resizing.currentInnerWidth === window.innerWidth) {
          this.resizing.isResizing = false;
        } else {
          this.resizing.currentInnerWidth = window.innerWidth;
          this.resizing.isResizing = false;
          this.drawGraph();
        }
        delete this.resizing.timeOut;
      }, 300);
    }
  }
}
