/* -- Angular Modules -- */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

/* -- Material Modules -- */
import { MaterialModule } from './material.module';

/* -- Routes --*/
import { ROUTES } from './app.routes';

/* -- COMPONENTS -- */
import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PatientsComponent } from './components/sub/patients/patients.component';
import { MyAccountComponent } from './components/sub/my-account/my-account.component';
import { PatientComponent } from './components/sub/patient/patient.component';
import { CompleteComponent } from './components/sub/complete/complete.component';
import { MyOrganizationComponent } from './components/sub/my-organization/my-organization.component';
import { GroupComponent } from './components/sub/group/group.component';
import { DailyGraphComponent } from './components/sub/patient/daily-graph/daily-graph.component';
import { SpaghettiGraphComponent } from './components/sub/patient/spaghetti-graph/spaghetti-graph.component';
import { GraphDetailsComponent } from './components/sub/patient/graph-details/graph-details.component';
import { HeatMapGraphComponent } from './components/sub/patient/heat-map-graph/heat-map-graph.component';
import { NotesComponent } from './components/sub/patient/notes/notes.component';

/* -- SERVICES -- */
import { ClientHttpsService } from './services/client-https.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './auth.guard';

/* -- PIPES -- */
import { RefreshTimePipe } from './pipes/refresh-time.pipe';
import { MemberTypePipe } from './pipes/member-type.pipe';

/* == THIRD PARTY MODULES --*/
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DailyFoodGraphComponent } from './components/sub/patient/daily-food-graph/daily-food-graph.component';
import { DurationBarGraphComponent } from './components/sub/patient/duration-bar-graph/duration-bar-graph.component';
import { DurationWeightGraphComponent } from './components/sub/patient/duration-weight-graph/duration-weight-graph.component';
import { WeeklyDataTableComponent } from './components/sub/patient/weekly-data-table/weekly-data-table.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    NavigationComponent,
    PatientsComponent,
    MyAccountComponent,
    PatientComponent,
    RefreshTimePipe,
    CompleteComponent,
    MyOrganizationComponent,
    GroupComponent,
    MemberTypePipe,
    DailyGraphComponent,
    SpaghettiGraphComponent,
    GraphDetailsComponent,
    HeatMapGraphComponent,
    NotesComponent,
    DailyFoodGraphComponent,
    DurationBarGraphComponent,
    DurationWeightGraphComponent,
    WeeklyDataTableComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    RouterModule.forRoot(ROUTES, { relativeLinkResolution: 'legacy' }),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatTableModule,
    MatPaginatorModule,
  ],
  providers: [
    ClientHttpsService,
    AuthService,
    AuthGuard
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
