import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'memberType'
})
export class MemberTypePipe implements PipeTransform {

  transform(memberType: string): string {
    switch (memberType) {
      case 'member':
        return 'Member';
      case 'admin':
        return 'Administrator';
      default:
        return 'null';
    }
  }

}
