import { Component, OnChanges, Input, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { ClientHttpsService } from '../../../../services/client-https.service';

interface Visible {
  createNote: boolean;
  destroyNote: boolean;
}

interface Note {
  date?: string;
  text?: string;
}

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css', '../patient.component.css']
})
export class NotesComponent implements OnChanges {
  @Input() baseDate: Date;
  @Input() patientId: string;
  @Input() isCreatingNote: boolean;
  @ViewChild('graph', { static: true }) graphWidth: ElementRef;
  public isResizing = false;
  public isLoading: boolean;
  public isVisible: Visible;
  public notes: Array<any> = [];
  public note: Note = {};
  public destroyNoteId: string;

  constructor(private client: ClientHttpsService) {
    this.isVisible = {
      createNote: false,
      destroyNote: false
    };
  }

  ngOnChanges() {
    this.pullNotes();

    if (this.isCreatingNote) {
      this.noteHelper('visible');
    }
  }

  private pullNotes(): void {
    this.isLoading = true;

    /* -- Pulls notes based on daily graph's date -- */
    this.client.note({
      noteType: 'patient',
      method: 'get',
      date: this.baseDate.toISOString(),
      patientId: this.patientId,
    }).subscribe(notes => {
      this.notes = notes;
      this.isLoading = false;
    }, err => {
      console.log(err);
      this.isLoading = false;
    });
  }

  public noteHelper(method: string, id?: string): void {
    switch (method) {
      case 'create':
        console.log('create note:', this.note);
        this.isVisible.createNote = false;
        this.client.note({
          noteType: 'patient',
          method: 'create',
          text: this.note.text,
          patientId: this.patientId,
          date: this.note.date
        }).subscribe((res: any) => {
          this.isLoading = true;
          this.pullNotes();
        }, err => {
          console.log(err);
          this.isLoading = false;
        });
        this.note = {};
        break;
      case 'cancel':
        this.note = {};
        this.isVisible.createNote = false;
        this.isVisible.destroyNote = false;
        this.destroyNoteId = '';
        break;
      case 'visible':
        this.isVisible.createNote = true;
        if (new Date().setHours(0, 0, 0, 0) === this.baseDate.setHours(0, 0, 0, 0)) {
          this.note.date = new Date().toISOString();
        } else {
          this.note.date = new Date(new Date(this.baseDate).setHours(6)).toISOString();
        }
        break;
      case 'destroy':
        this.isVisible.destroyNote = false;
        this.client.note({ noteType: 'patient', method: 'destroy', noteId: this.destroyNoteId }).subscribe(res => {
          this.isLoading = true;
          this.pullNotes();
        }, err => {
          console.log(err);
          this.isLoading = false;
        });
        this.destroyNoteId = '';
        break;
      case 'visible-delete':
        this.destroyNoteId = id;
        this.isVisible.destroyNote = true;
        break;
    }
  }
}
