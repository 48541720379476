import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { COLOR_STYLES, GRAPH_HEIGHTS, MILLI_TO_DAY, CAL_PER_NUT_GRAM } from '../config';
import { ClientHttpsService } from '../../../../services/client-https.service';
import { Observable } from 'rxjs';
import * as d3 from 'd3';

interface Visible {
  foodDetails: boolean;
}

interface Resizing {
  isResizing: boolean;
  currentInnerWidth?: number;
  timeOut?: NodeJS.Timer;
}

@Component({
  selector: 'app-daily-food-graph',
  templateUrl: './daily-food-graph.component.html',
  styleUrls: ['./daily-food-graph.component.css', '../patient.component.css']
})
export class DailyFoodGraphComponent implements OnChanges {
  @Input() baseDate: Date;
  @Input() patientId: string;
  @Input() isDataLoaded: boolean;
  @Output() baseDateChangedEvent = new EventEmitter<Date>();
  @ViewChild('graph', { static: true }) graphWidth: ElementRef;
  private baseY = GRAPH_HEIGHTS.svg - 80;
  private maxY = 200;
  public resizing: Resizing;
  public isLoading: boolean;
  public isVisible: Visible;
  public foodDetails: any;
  public foodData = [];

  public set graphTime(date: Date) {
    this.baseDate = new Date(new Date(date).setHours(0, 0, 0, 0));
    this.baseDateChangedEvent.emit(this.baseDate);
    this.drawGraph();
  }

  public get graphTime(): Date {
    return this.baseDate;
  }

  // For opacity styles
  public get isLoadingBinary(): number {
    if (this.isLoading) {
      return 0;
    } else {
      return 1;
    }
  }

  // Changes time frame for daily graph
  public changeDailyDate(numberChange?: number): void {
    if (numberChange) {
      this.graphTime = new Date(this.graphTime.getTime() + numberChange * MILLI_TO_DAY);
      this.drawGraph();
    }
  }

  constructor(private client: ClientHttpsService) { }

  ngOnChanges() {
    this.resizing = { isResizing: false };

    this.isLoading = true;

    this.isVisible = {
      foodDetails: false,
    };

    d3.select('#daily-food-graph')
      .attr('height', GRAPH_HEIGHTS.svg)
      .attr('width', this.graphWidth.nativeElement.offsetWidth);

    if (this.isDataLoaded) {
      this.dataObservable.subscribe(data => {
        this.formatData(data);
        this.drawGraph();
      }, (err) => {
        console.log(err);
        this.isLoading = false;
      });
    }
  }

  private get dataObservable(): Observable<any> {
    return this.client.getClientDayData(
      this.client.orgIdDefault,
      this.patientId,
      this.graphTime.toISOString()
    );
  }

  private formatData(data: any): void {
    this.foodData = [];

    const manualEntries = data.manual.sort((a: any, b: any) => {
      return +new Date(a.time) - +new Date(b.time);
    });

    // categorize manual entries
    manualEntries.forEach((d: any) => {
      if (new Date(d.time).setHours(0, 0, 0, 0) === this.graphTime.getTime()) {
         if (d.type === 'food') {
          this.foodData.push(JSON.parse(JSON.stringify(d)));
        }
      }
    });

    // Checks for foods with close times
    // Combines foods within 45 minutes
    for (let i = 0; i < this.foodData.length; i++) {
      if (i > 0) {
        const timeDifference = new Date(
          this.foodData[i].time).getTime() - new Date(this.foodData[i - 1].time).getTime();

        if (timeDifference / 1000 / 60 < 45) {  // within 45 minutes
          if (this.foodData[i - 1].hasOwnProperty('foods') && this.foodData[i - 1].foods.length > 0) {
            // previous food entry has multiple foods
            const timeframeDifference = new Date(
              this.foodData[i].time).getTime()
              - new Date(this.foodData[i - 1].timeframe.start)
                .getTime();

            this.foodData[i - 1].foods.push(this.foodData[i]);
            this.foodData[i - 1].timeframe.end = this.foodData[i].time;
            this.foodData[i - 1].time = new Date(
              new Date(this.foodData[i - 1].time).getTime()
              + (timeframeDifference / 2))
              .toISOString();
          } else {
            // previous food entry does not have multiple foods
            this.foodData[i - 1].foods = [
              JSON.parse(JSON.stringify(this.foodData[i - 1])),
              JSON.parse(JSON.stringify(this.foodData[i]))
            ];

            this.foodData[i - 1].timeframe = {
              start: this.foodData[i - 1].time,
              end: this.foodData[i].time
            };

            this.foodData[i - 1].time = new Date(
              new Date(this.foodData[i - 1].time).getTime()
              + (timeDifference / 2))
              .toISOString();
          }

          for (const nut in this.foodData[i - 1].nutrients) {
            if (nut) {
              this.foodData[i - 1].nutrients[nut] = Number(this.foodData[i - 1].nutrients[nut])
                + Number(this.foodData[i].nutrients[nut]);
            }
          }

          this.foodData.splice(i, 1);
          this.foodData[i - 1].isMultiple = true;
          i--;
        } else {
          this.foodData[i].isMultiple = false;
        }
      }
    }

    const max = Math.round(d3.max(this.foodData, (d: any) => this.totalCal(d)) + 10);
    this.maxY = max > this.maxY ? max : this.maxY;
  }

  private totalCal(food: any): number {
    return food.nutrients.carbohydrate * CAL_PER_NUT_GRAM.carbohydrate +
      food.nutrients.fat * CAL_PER_NUT_GRAM.fat +
      food.nutrients.protein * CAL_PER_NUT_GRAM.protein;
  }

  private getCarbHeight(food: any, yScale: any): number {
    return this.baseY - yScale(food.nutrients.carbohydrate * CAL_PER_NUT_GRAM.carbohydrate);
  }

  private getFatHeight(food: any, yScale: any): number {
    return this.baseY - yScale(food.nutrients.fat * CAL_PER_NUT_GRAM.fat);
  }

  private getProteinHeight(food: any, yScale: any): number {
    return this.baseY - yScale(food.nutrients.protein * CAL_PER_NUT_GRAM.protein);
  }

  private getCarbY(food: any, yScale: any): number {
    return this.baseY - (this.getFatHeight(food, yScale)
      + this.getProteinHeight(food, yScale)
      + this.getCarbHeight(food, yScale));
  }

  private getFatY(food: any, yScale: any): number {
    return this.baseY - (this.getFatHeight(food, yScale)
      + this.getProteinHeight(food, yScale));
  }

  private getProteinY(food: any, yScale: any): number {
    return this.baseY - this.getProteinHeight(food, yScale);
  }

  /* ===== DRAW DAILY GRAPH ===== */
  private drawGraph(): void {
    let xScale, yScale;
    const svg = d3.select('svg#daily-food-graph');

    this.isLoading = true;

    /* -- remove preexisting elements -- */
    svg.selectAll('g.primary-axis')
      .remove();

    svg.selectAll('g.target-axis')
      .remove();

    xScale = d3.scaleTime()
      .domain([this.graphTime, new Date(this.graphTime.getTime() + MILLI_TO_DAY)]) // Time frame of one day
      .range([75, this.graphWidth.nativeElement.offsetWidth - 75]);

    yScale = d3.scaleLinear()
      .domain([0, this.maxY])
      .range([GRAPH_HEIGHTS.svg - 80, 30]);

    const xAxis = d3.axisBottom(xScale)
      .ticks(5)
      .tickFormat((d: Date) => d.getHours() + ':00');

    const yAxis = d3.axisLeft(yScale)
      .ticks(7);

    /* -- Draws x-axis ticks -- */
    svg.append('g')
      .attr('transform', `translate(0, ${GRAPH_HEIGHTS.svg - 35})`)
      .classed('primary-axis', true)
      .classed('x-axis', true)
      .call(xAxis);

    /* -- Draw y-axis ticks -- */
    svg.append('g')
      .attr('transform', 'translate(75, 0)')
      .classed('primary-axis', true)
      .classed('y-axis', true)
      .call(yAxis);

    /* -- Format Axes-- */
    svg.selectAll('.primary-axis')
      .selectAll('path')
      .remove();

    svg.selectAll('.primary-axis')
      .selectAll('g')
      .selectAll('line')
      .remove();

    svg.selectAll('.primary-axis')
      .selectAll('g')
      .selectAll('text')
      .attr('fill', COLOR_STYLES.graph)
      .attr('dy', '.2em')
      .attr('style', 'font-size: 1.4em;');

    /* -- Horizontal graph lines -- */
    svg.selectAll('g.y-axis g.tick')
      .append('line')
      .classed('grid-line', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
      .attr('y2', 0)
      .attr('stroke', COLOR_STYLES.graph);

    svg.selectAll('g.target-axis g.tick') // Target grid lines
      .append('line')
      .classed('grid-target-line', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
      .attr('y2', 0)
      .attr('stroke', COLOR_STYLES.bloodGlucoseHighlightText)
      .attr('stroke-dasharray', '10,10');

    svg.selectAll('rect.carbohydrate')
      .data(this.foodData)
      .join(
        enter => enter.append('rect')
          .attr('x', (d) => xScale(new Date(d.time)) - 3)
          .attr('y', (d) => this.getCarbY(d, yScale) + this.getCarbHeight(d, yScale))
          .attr('height', 0)
          .call(e => e.transition()
            .delay(600)
            .duration(300)
            .attr('height', (d: any) => this.getCarbHeight(d, yScale))
            .attr('y', (d) => this.getCarbY(d, yScale)))
      )
      .classed('carbohydrate', true)
      .classed('nut-bar', true)
      .attr('fill', COLOR_STYLES.food.carbohydrate)
      .attr('width', '6px');

    svg.selectAll('rect.fat')
      .data(this.foodData)
      .join(
        enter => enter.append('rect')
          .attr('x', (d) => xScale(new Date(d.time)) - 3)
          .attr('y', (d) => this.getFatY(d, yScale) + this.getFatHeight(d, yScale))
          .attr('height', 0)
          .call(e => e.transition()
            .delay(300)
            .duration(300)
            .attr('height', (d: any) => this.getFatHeight(d, yScale))
            .attr('y', (d) => this.getFatY(d, yScale)))
      )
      .classed('fat', true)
      .classed('nut-bar', true)
      .attr('fill', COLOR_STYLES.food.fat)
      .attr('width', '6px');

    svg.selectAll('rect.protein')
      .data(this.foodData)
      .join(
        enter => enter.append('rect')
          .attr('x', (d) => xScale(new Date(d.time)) - 3)
          .attr('y', (d) => this.getProteinY(d, yScale) + this.getProteinHeight(d, yScale))
          .attr('height', 0)
          .call(e => e.transition()
            .duration(300)
            .attr('height', (d: any) => this.getProteinHeight(d, yScale))
            .attr('y', (d) => this.getProteinY(d, yScale)))
      )
      .classed('protein', true)
      .classed('nut-bar', true)
      .attr('fill', COLOR_STYLES.food.protein)
      .attr('width', '6px');

    d3.selectAll('rect.nut-bar')
      .on('click', d => {
        console.log(d);
        this.foodDetails = d;
        this.isVisible.foodDetails = true;
      });

    d3.selectAll('rect.nut-bar')
      .raise();

    this.isLoading = false;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    if (this.resizing.currentInnerWidth !== window.innerWidth) {
      this.resizing.isResizing = true;
      if (this.resizing.hasOwnProperty('timeOut')) {
        clearTimeout(this.resizing.timeOut);
      }

      // Create timeout for 300ms
      this.resizing.timeOut = setTimeout(() => {
        if (this.resizing.currentInnerWidth === window.innerWidth) {
          this.resizing.isResizing = false;
        } else {
          this.resizing.currentInnerWidth = window.innerWidth;
          this.resizing.isResizing = false;
          this.drawGraph();
        }
        delete this.resizing.timeOut;
      }, 300);
    }
  }
}
