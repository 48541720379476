import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return Observable.create(observer => {
      this.auth.getAccessToken().subscribe(() => {
        observer.next(true);
        observer.complete();
      }, () => {
        this.router.navigateByUrl('login');
        observer.next(false);
        observer.complete();
      });
    });
  }
}
