import { Component, OnInit } from '@angular/core';
import { ClientHttpsService } from '../../../services/client-https.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {
  public accountData: any = {
    firstName: null,
    lastName: null,
    title: null,
    email: null
  };

  public passwordReset: any = {
    confirmPassword: null,
    newPassword: null,
    confirmCode: null,
    isVisible: false,
    isSuccess: false,
    isLoading: false,
    isError: {
      limit: false,             // You have reached your limit for password resets. Please try again later.
      invalidPassword: false,   // Your password must be at least eight characters and contain an uppercase letter, lowercase letter, number, and symbol.
      invalidMatch: false,      // The passwords you have entered do not match.
      generic: false,           // An error occurred. Please try again later.
      missingCode: false        // Please enter the six digit code sent to your email address.
    }
  };

  constructor(private client: ClientHttpsService, private auth: AuthService) { }

  ngOnInit() {
    this.client.getProviderData().subscribe(val => {
      if (!val.hasOwnProperty('firstName') || !val.hasOwnProperty('lastName') || !val.hasOwnProperty('title')) {
        console.log('missing properties');
      } else {
        this.accountData.firstName = val.firstName;
        this.accountData.lastName = val.lastName;
        this.accountData.email = val.email;
        this.accountData.title = val.title;
      }
    }, err => console.log(err));
  }

  public resetPassword() {
    console.log(this.passwordReset);
    this.passwordReset.isLoading = true;

    for (const err in this.passwordReset.isError) {
      this.passwordReset.isError[err] = false;
    }

    if (this.passwordReset.confirmPassword !== this.passwordReset.newPassword) {
      this.passwordReset.isError.invalidMatch = true;
    } else if (this.passwordReset.newPassword.length < 8) {
      this.passwordReset.isError.invalidPassword;
    } else if (this.passwordReset.confirmCode.length !== 6) {
      this.passwordReset.isError.missingCode = true;
    } else {
      this.auth.forgotPasswordReset(this.passwordReset.confirmCode, this.passwordReset.newPassword).subscribe({
        next: () => {
          this.passwordReset.isLoading = false;
          this.passwordReset.isSuccess = true;
          this.passwordReset.isVisible = false;
        },
        error: err => {
          this.passwordReset.isLoading = false;
          console.log(err);
          switch (err.code) {
            case "InvalidPasswordException":
              this.passwordReset.isError.invalidPassword = true
              break;
            case "InvalidParameterException":
              break;
            case "ExpiredCodeException":
              break;
            default:
              break;
          }
        }
      });
    }
  }

  public initResetPassword() {
    this.passwordReset.isLoading = true;

    for (const err in this.passwordReset.isError) {
      this.passwordReset.isError[err] = false;
    }

    this.auth.forgotPassword(this.accountData.email).subscribe({
      next: res => {
        this.passwordReset.isVisible = true;
        this.passwordReset.isLoading = false;
      },
      error: err => {
        this.passwordReset.isLoading = false;
        switch (err.code) {
          case "LimitExceededException":
            this.passwordReset.isError.limit = true;
            break;
          default:
            this.passwordReset.isError.generic = true;
            console.log(err.code);
            break;
        }
      }
    });
  }
}
