import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { int } from 'aws-sdk/clients/datapipeline';
import { Observable } from 'rxjs';
import { ClientHttpsService } from '../../../../services/client-https.service';

const exerciseMinutesTarget = 150;
const kgToLbs = 2.20462;

class WeeklyDatum {
  weekNumber: int;
  startDate: Date;
  endDate: Date;
  exerciseData: Array<any> = [];
  weightData: Array<any> = [];
  exerciseDuration: int = 0;
  lastLoggedWeight: number;
  lastLoggedWeightDate: Date;
  percentWeightChange: number;

  get didHitTarget(): boolean {
    return this.exerciseDuration >= exerciseMinutesTarget;
  }

  get dateRange(): String {
    if (this.endDate) {
      return (this.startDate.getMonth() + 1) + "/" + this.startDate.getDate() + "/" + this.startDate.getFullYear() + " - " + (this.endDate.getMonth() + 1) + "/" + this.endDate.getDate() + "/" + this.endDate.getFullYear();
    } else {
      return (this.startDate.getMonth() + 1) + "/" + this.startDate.getDate() + "/" + this.startDate.getFullYear() + " - now";
    }
    
  }

  get percentString(): String {
    if (this.percentWeightChange) {
      return this.percentWeightChange.toFixed(1) + "%";
    } else {
      return "--";
    }
  }

  get weightChangeStyleColor(): String {
    if (this.percentWeightChange) {
      if (this.percentWeightChange > 0) {
        return "red";
      } else if (this.percentWeightChange < 0) {
        return "green";
      } else {
        return "black"
      }
    } else {
      return "black";
    }
  }
}

@Component({
  selector: 'app-weekly-data-table',
  templateUrl: './weekly-data-table.component.html',
  styleUrls: ['./weekly-data-table.component.css']
})
export class WeeklyDataTableComponent implements OnInit, AfterViewInit {
  @Input() baseDate: Date;
  @Input() patientId: string;
    
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dataSource: MatTableDataSource<WeeklyDatum>;


  programStartDate: Date = new Date(2021, 0, 1);
  displayedColumns: string[] = ['weekNo', 'duration', 'weight', 'percentWeightChange', 'dateRange'];

  weeklyData: Array<WeeklyDatum>;
  weeklyMilliseconds: int = 1000 * 60 * 60 * 24 * 7;
  noOfWeeks: int;
  firstWeight: number;
  firstWeightDate: number;
  lastWeight: number;
  lastWeightDate: Date;

  constructor(public client: ClientHttpsService) { }

  ngOnInit(): void {
    this.noOfWeeks = Math.ceil(((new Date()).getTime() - this.programStartDate.getTime()) / this.weeklyMilliseconds);
    this.weeklyData = (new Array(this.noOfWeeks)).fill(0).map((_, i) => {
      let datum = new WeeklyDatum();
      datum.weekNumber = i + 1;
      datum.startDate = new Date(this.programStartDate.getTime() + i * this.weeklyMilliseconds);

      let end = new Date(this.programStartDate.getTime() + (i + 1) * this.weeklyMilliseconds);
      if (end.getTime() <= (new Date()).getTime()) {
        datum.endDate = end;
      }
      
      return datum;
    });

    // this.formatData(dummyData);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    this.dataObservable.subscribe(data => {
      this.formatData(data);
    });
  }

  get numberOfWeeksHitTarget(): int {
    let total = 0;

    this.weeklyData.forEach(d => {
      if (d.exerciseDuration >= exerciseMinutesTarget) {
        total++;
      }
    });

    return total;
  }

  private get dataObservable(): Observable<any> {
    return this.client.getClientNutTimeframeData(
      this.client.orgIdDefault,
      this.patientId,
      this.programStartDate.toISOString(),
      (new Date()).toISOString()
    );
  }

  private formatData(data: any): void {
    console.log(data);
    
    data.forEach((d: any) => {
      d.time = new Date(new Date(d.time).setHours(0, 0, 0, 0));
      let i = Math.floor((d.time.getTime() - this.programStartDate.getTime()) / this.weeklyMilliseconds);

      // if (d.type === 'food') {
      //   let exists = false;
      //   this.foods.forEach((f: any) => {
      //     if (f.time.toISOString() === d.time.toISOString()) {
      //       Object.keys(f.nutritionFacts).forEach((n) => {
      //         f.nutritionFacts[n] = +f.nutritionFacts[n] + +d.nutritionFacts[n];
      //       });

      //       exists = true;
      //     }
      //   });

      //   if (!exists) {
      //     Object.keys(d.nutritionFacts).forEach((n) => {
      //       d.nutritionFacts[n] = +d.nutritionFacts[n];
      //     });

      //     this.foods.push(d);
      //   }
      // } else 
      
      if (d.type === 'exercise') {
        this.weeklyData[i].exerciseDuration += +d.duration;
        this.weeklyData[i].exerciseData.push(d);
      } else if (d.type === 'weight') {
        d.weight *= 2.20462;
        this.lastWeight = +d.weight;
        this.lastWeightDate = d.time;

        if (!this.firstWeightDate) {
          this.firstWeight = +d.weight;
          this.firstWeightDate = d.time;
        }

        if (this.weeklyData[i].lastLoggedWeightDate != null) {
          if (this.weeklyData[i].lastLoggedWeightDate.getTime() < d.time.getTime()) {
            this.weeklyData[i].lastLoggedWeight = +d.weight;
            this.weeklyData[i].lastLoggedWeightDate = d.time;
          }
        } else {
          this.weeklyData[i].lastLoggedWeight = +d.weight;
          this.weeklyData[i].lastLoggedWeightDate = d.time;
        }
      }
    });

    let lastLoggedWeight: number;
    let lastLoggedIndex: int;

    this.weeklyData.forEach((d, i) => {
      if (d.lastLoggedWeightDate != null) {
        if (lastLoggedIndex == i - 1) {
          d.percentWeightChange = (d.lastLoggedWeight - lastLoggedWeight) / lastLoggedWeight * 100;
        }

        lastLoggedWeight = d.lastLoggedWeight;
        lastLoggedIndex = i;
      }
    });

    this.dataSource = new MatTableDataSource<WeeklyDatum>(this.weeklyData.reverse());
    
    console.log(this.weeklyData);
  }
}

// const dummyData = [
//   {
//     "type": "exercise",
//     "duration": "160",
//     "time": new Date(2021, 1, 6)
//   },
//   {
//     "type": "exercise",
//     "duration": "75",
//     "time": new Date(2021, 1, 15)
//   },
//   {
//     "type": "weight",
//     "weight": "98",
//     "time": new Date(2021, 0, 18)
//   },
//   {
//     "type": "weight",
//     "weight": "90",
//     "time": new Date(2021, 0, 22)
//   },
//   {
//     "type": "weight",
//     "weight": "80",
//     "time": new Date(2021, 1, 4)
//   },
//   {
//     "type": "weight",
//     "weight": "78",
//     "time": new Date(2021, 1, 7)
//   },
//   {
//     "type": "weight",
//     "weight": "76",
//     "time": new Date(2021, 1, 14)
//   },
//   {
//     "type": "weight",
//     "weight": "75",
//     "time": new Date(2021, 1, 15)
//   },
// ];
