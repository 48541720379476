import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ClientHttpsService } from '../../../services/client-https.service';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css'],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class PatientsComponent implements OnInit {
  public searchQuery = '';
  public sortType = 'Last Name';
  public sortTypes: Array<string> = ['Last Name', 'First Name'];
  public patients: Array<any> = [];
  public isDisplayed = {
    patientsList: false,
    isLoading: {
      patientsList: false
    }
  };
  public clickType = {
    sortMenu: false
  };


  constructor(private ref: ChangeDetectorRef, private router: Router, private client: ClientHttpsService) { }

  ngOnInit() {
    this.isDisplayed.isLoading.patientsList = true;
    this.client.getOrganizationData(this.client.orgIdDefault).subscribe(val => {
      this.isDisplayed.isLoading.patientsList = false;
      if (val.patients.length !== 0) {
        this.isDisplayed.patientsList = true;
        this.patients = val.patients;

        // Adds display property for filtering
        this.patients.forEach((v, i) => {
          this.patients[i].display = true;
        });
        this.sortPatients(this.sortType);
      }
    }, err => {
      this.isDisplayed.isLoading.patientsList = false;
      console.log(err);
    });
  }

  public sortPatients(type: string) {
    this.sortTypes.forEach(sortType => {
      if (type === sortType) {
        this.sortType = type;
        switch (type) {
          case 'Last Name':
            this.patients.sort((a, b) => {
              if (a.lastName < b.lastName) {
                return -1;
              } else if (a.lastName > b.lastName) {
                return 1;
              } else {
                return 0;
              }
            });
            break;
          case 'First Name':
            this.patients.sort((a, b) => {
              if (a.firstName < b.firstName) {
                return -1;
              } else if (a.firstName > b.firstName) {
                return 1;
              } else {
                return 0;
              }
            });
            break;
        }
      }
    });
    this.clickType.sortMenu = false;
  }

  public onClick(event) {
    const elementId = event.target.id;

    if (elementId === 'patients-sort') {
      this.clickType.sortMenu = !this.clickType.sortMenu;
    } else if (elementId === 'sort-menu') {
      return;
    } else {
      this.clickType.sortMenu = false;
    }
  }

  public filterPatients() {
    // Used by search field
    if (this.searchQuery === '') {
      this.patients.forEach(patient => {
        patient.display = true;
      });
    } else {
      this.patients.forEach(patient => {
        const queries = this.searchQuery.split(' ');
        for (let i = 0; i < queries.length; i++) {
          if (patient.firstName.toLowerCase().indexOf(queries[i].toLowerCase()) !== 0
            && patient.lastName.toLowerCase().indexOf(queries[i].toLowerCase()) !== 0) {
            patient.display = false;
            break;
          } else {
            patient.display = true;
          }
        }
      });
    }
  }

  public redirectTo(patient) {
    this.router.navigateByUrl('dashboard/patients/' + patient.id);
  }
}
