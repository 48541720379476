import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientHttpsService } from '../../../services/client-https.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {
  public isRemove = false;
  public destroyNoteId = '';
  public isVisible = {
    loading: {
      group: false,
      notes: false
    },
    remove: false,
    confirmDestroy: false,
    createNote: false,
    destroyNote: false,
  };
  public groupData = {
    groupName: '',
    patients: [],
    notes: []
  };
  public noteDate = new Date(new Date().setHours(0, 0, 0, 0));

  set setNoteDate(date) {
    this.noteDate = new Date(new Date(date).setHours(0, 0, 0, 0));
    this.isVisible.loading.notes = true;
    this.client.note({
      noteType: 'group',
      method: 'get',
      date: this.noteDate.toISOString(),
      groupName: this.groupData.groupName
    }).subscribe(notes => {
      this.groupData.notes = notes;
      this.isVisible.loading.notes = false;
    });
  }

  get setNoteDate() {
    return this.noteDate;
  }

  public note: any = {
    date: '',
    text: ''
  };

  constructor(public router: Router, public client: ClientHttpsService, public aRoute: ActivatedRoute) { }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      this.isVisible.loading.group = true;
      this.isVisible.loading.notes = true;

      this.groupData.groupName = params.id;   // Group Name from URL
      this.client.group(this.groupData.groupName, 'pull-group').subscribe(val => {
        this.groupData.patients = val;
        this.isVisible.loading.group = false;
      }, err => {
        console.log('error:', err);
        this.router.navigateByUrl('dashboard');
      });
    });

    this.client.note({
      noteType: 'group',
      method: 'get',
      date: this.noteDate.toISOString(),
      groupName: this.groupData.groupName
    }).subscribe(notes => {
      this.groupData.notes = notes;
      this.isVisible.loading.notes = false;
    }, err => {
      this.isVisible.loading.notes = true;
      console.log(err);
    });
  }

  redirectTo(patient): void {
    this.router.navigateByUrl(`dashboard/patients/${patient.id}`);
  }

  removeFromGroup(patient): void {
    this.client.group(this.groupData.groupName, 'remove', patient.id).subscribe(val => {
      console.log('success');
      this.client.group(this.groupData.groupName, 'pull-group', '', true).subscribe(patients => {
        this.groupData.patients = patients;
      }, err => {
        console.log('error:', err);
        this.router.navigateByUrl('dashboard');
      });
    }, err => console.log(err));
  }

  destroyGroup(): void {
    this.client.group(this.groupData.groupName, 'destroy-group').subscribe(val => {
      this.router.navigateByUrl('dashboard/patients');
    }, err => console.log(err));
  }

  public noteHelper(method: string, id?: string): void {
    switch (method) {
      case 'create':
        this.isVisible.createNote = false;
        this.client.note({
          noteType: 'group',
          method: 'create',
          text: this.note.text,
          groupName: this.groupData.groupName,
          date: this.note.date
        }).subscribe(res => {
          this.isVisible.loading.notes = true;
          this.client.note({
            noteType: 'group',
            method: 'get',
            date: this.noteDate.toISOString(),
            groupName: this.groupData.groupName
          }).subscribe(notes => {
            this.groupData.notes = notes;
            this.isVisible.loading.notes = false;
          });
        }, err => {
          console.log(err);
          this.isVisible.loading.notes = false;
        });
        this.note = {};
        break;
      case 'cancel':
        this.note = {};
        this.isVisible.createNote = false;
        this.isVisible.destroyNote = false;
        this.destroyNoteId = '';
        break;
      case 'visible':
        this.isVisible.createNote = true;
        if (new Date().setHours(0, 0, 0, 0) === this.noteDate.setHours(0, 0, 0, 0)) {
          this.note.date = new Date().toISOString();
        } else {
          this.note.date = new Date(new Date(this.noteDate).setHours(6)).toISOString();
        }
        break;
      case 'destroy':
        this.isVisible.destroyNote = false;
        this.client.note({ noteType: 'group', method: 'destroy', noteId: this.destroyNoteId }).subscribe(res => {
          this.isVisible.loading.notes = true;
          this.client.note({
            noteType: 'group',
            method: 'get',
            date: this.noteDate.toISOString(),
            groupName: this.groupData.groupName
          }).subscribe(notes => {
            this.groupData.notes = notes;
            this.isVisible.loading.notes = false;
          });
        }, err => {
          console.log(err);
          this.isVisible.loading.notes = false;
        });
        this.destroyNoteId = '';
        break;
      case 'visible-delete':
        this.destroyNoteId = id;
        this.isVisible.destroyNote = true;
        break;
    }
  }
}
