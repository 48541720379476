// TODO: push user choice of cgm or manual
// TODO: change this.parentNode... to selection.raise()

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
// import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientHttpsService } from '../../../services/client-https.service';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {
  @ViewChild('star', { static: true }) starEl: ElementRef;
  public isUsingCGM = false;     // data is currently being pulled from CGM
  public isVisible: any = {
    cgmConnection: false      // patient has connected CGM
  };

  public groupNames: Array<any> = [];
  public patient: Patient;
  public baseDate: Date;
  public whenRefreshed: Date;
  public isCreatingNote: boolean;

  set graphBaseDate(date: Date) {
    this.isCreatingNote = false;
    this.baseDate = date;
  }

  get graphBaseDate(): Date {
    return this.baseDate;
  }

  get showBgData(): boolean {
    switch(this.patient.medicalCondition) {
      case 'type2diabetes':
        return true;
      case 'type1diabetes':
        return true;
      default: 
        return false;
    }
  }

  constructor(public eRef: ElementRef, public client: ClientHttpsService, public router: Router, public aRoute: ActivatedRoute) {
    this.graphBaseDate = new Date(new Date().setHours(0, 0, 0, 0));

    this.isUsingCGM = false;
    this.isCreatingNote = false;

    this.patient = {
      hasData: false,
      isStarred: false,
      firstName: '',
      lastName: '',
      medicalCondition: '',
      userId: null
    };
  }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      this.patient.userId = params.id;   // UserId from URL
      this.client.getClientData(this.client.orgIdDefault, this.patient.userId).subscribe(val => {
        if (val.hasOwnProperty('thirdPartyTokens') && val.thirdPartyTokens.hasOwnProperty('dexcom')) {
          this.isVisible.cgmConnection = true;
        }
        this.patient.hasData = true;
        this.patient.firstName = val.firstName;
        this.patient.lastName = val.lastName;
        this.patient.medicalCondition = val.medical[0];
      }, err => {
        console.log(err);
      });

      this.client.getProviderData().subscribe(val => {
        for (const patient in val.starredPatients) {
          if (val.starredPatients[patient] === this.patient.userId) {
            this.patient.isStarred = true;
            break;
          }
        }

        if (val.hasOwnProperty('groupNames')) {
          this.groupNames = val.groupNames;
        }
      });

      // Sets refreshed time value
      this.whenRefreshed = new Date();

    });
  }

  // Change star status of user
  public changeStar(): void {
    this.patient.isStarred = !this.patient.isStarred;
    this.client.postStarChange(this.patient.userId, this.patient.isStarred).subscribe(() => { }, err => console.log(err));
  }

  public addToGroup(group: string): void {
    this.client.group(group, 'add', this.patient.userId).subscribe(val => {
      console.log(val);
    }, err => {
      console.log(err);
    });
  }

  public changeVisible(type: string, value: boolean): void {
    if (this.isVisible.hasOwnProperty(type)) {
      this.isVisible[type] = value;
    }
  }
}
