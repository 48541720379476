import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

const BASE_API_URL = 'https://fiw9m3d5ud.execute-api.us-east-1.amazonaws.com/prod/provider/';

@Injectable()
export class ClientHttpsService {
  public orgIdDefault = '';
  private changeFlag: any = {
    group: {},
    providerData: false
  };

  private groups: any = {};
  private providerData: any = null;
  private organizationData: any = null;
  private clientData: any = {};
  private clientDayData: any = {
    user: {},
    single: {},
    bgRange: {},
    nutRange: {},
    ambulatory: {}
  };

  constructor(private http: HttpClient, private auth: AuthService, private router: Router) { }

  public clearAllCache(): void {
    this.orgIdDefault = '';
    this.changeFlag = {
      group: {},
      providerData: false
    };

    this.groups = {};
    this.providerData = null;
    this.organizationData = null;
    this.clientData = {};
    this.clientDayData = {
      user: {},
      single: {},
      bgRange: {},
      nutRange: {},
      ambulatory: {}
    };
  }

  public getClientData(orgId: string, userId: string, forcePull: boolean = false): Observable<any> {
    const getClientData = (observer, oid) => {
      this.postRequest('patient/pull-patient-data', {
        organizationId: oid,
        patientId: userId
      }).subscribe(val => {
        if (val.hasOwnProperty('errorMessage')) {
          observer.error(val);
        } else {
          this.clientData[userId] = val;
          /*this.timeOut.clientDayData.single[userId + date] = setTimeout(() => {
            delete this.clientDayData.single[userId + date];
          }, 300000);*/
          observer.next(val);
          observer.complete();
        }
      }, err => {
        observer.error(err);
      });
    };

    return new Observable(observer => {
      if (this.clientData.hasOwnProperty(userId) && !forcePull) {
        observer.next(this.clientData[userId]);
        observer.complete();
      } else if (orgId === '') {
        this.getProviderData().subscribe(() => {
          getClientData(observer, this.orgIdDefault);
        }, err => {
          observer.error(err);
        });
      } else {
        getClientData(observer, orgId);
      }
    });
  }

  public getClientDayData(orgId: string, userId: string, date: string, forcePull: boolean = false): Observable<any> {
    const getClientDay = (observer: any, oid: string) => {
      this.postRequest('patient/pull-patient-items', {
        organizationId: oid,
        patientId: userId,
        dataType: 'single-date',
        date: date
      }).subscribe(val => {
        if (val == null) {
          observer.error();
        } else if (val.hasOwnProperty('errorMessage')) {
          observer.error(val);
        } else {
          this.clientDayData.single[userId + date] = val;
          /*this.timeOut.clientDayData.single[userId + date] = setTimeout(() => {
            delete this.clientDayData.single[userId + date];
          }, 300000);*/
          observer.next(val);
          observer.complete();
        }
      }, err => {
        observer.error(err);
      });
    };

    return new Observable(observer => {
      // checks for cached data
      if (this.clientDayData.single.hasOwnProperty(userId + date) && !forcePull) {
        observer.next(this.clientDayData.single[userId + date]);
      } else if (orgId === '') {
        this.getProviderData().subscribe(() => {
          getClientDay(observer, this.orgIdDefault);
        }, err => {
          observer.error(err);
        });
      } else {
        getClientDay(observer, orgId);
      }
    });
  }

  public getClientBGTimeframeData(orgId: string, userId: string, start: string, end: string, forcePull: boolean = false): Observable<any> {
    const getClientBG = (observer, oid) => {
      this.postRequest('patient/pull-patient-items', {
        organizationId: oid,
        patientId: userId,
        dataType: 'bg-date-range',
        dateRange: {
          start: start,
          end: end
        }
      }).subscribe(val => {
        if (val == null) {
          observer.error();
        } else if (val.hasOwnProperty('errorMessage')) {
          observer.error(val);
        } else {
          this.clientDayData.bgRange[userId + start + end] = val;
          /*this.timeOut.clientDayData.bgRange[userId + start + end] = setTimeout(() => {
            delete this.clientDayData.bgRange[userId + start + end]
          }, 300000);*/

          observer.next(val);
          observer.complete();
        }
      }, err => {
        observer.error(err);
      });
    };

    return new Observable(observer => {
      // checks for cached data
      if (this.clientDayData.bgRange.hasOwnProperty(userId + start + end) && !forcePull) {
        observer.next(this.clientDayData.bgRange[userId + start + end]);
      } else if (orgId === '') {
        this.getProviderData().subscribe(() => {
          getClientBG(observer, this.orgIdDefault);
        }, err => {
          observer.error(err);
        });
      } else {
        getClientBG(observer, orgId);
      }
    });
  }

  public getClientNutTimeframeData(orgId: string, userId: string, start: string, end: string, forcePull: boolean = false): Observable<any> {
    end = new Date(new Date(end).setHours(23, 59, 59, 59)).toISOString();

    const getClientNut = (observer, oid: string) => {
      this.postRequest('patient/pull-patient-items', {
        organizationId: oid,
        patientId: userId,
        dataType: 'nut-date-range',
        dateRange: {
          start: start,
          end: end
        }
      }).subscribe(val => {
        if (val == null) {
          observer.error();
        } else if (val.hasOwnProperty('errorMessage')) {
          observer.error(val);
        } else {
          this.clientDayData.nutRange[userId + start + end] = val;
          observer.next(val);
          observer.complete();
        }
      }, err => {
        observer.error(err);
      });
    };

    return new Observable(observer => {
      if (this.clientDayData.nutRange.hasOwnProperty(userId + start + end) && !forcePull) {
        observer.next(this.clientDayData.nutRange[userId + start + end]);
      } else if (orgId === '') {
        this.getProviderData().subscribe(() => {
          getClientNut(observer, this.orgIdDefault);
        }, err => {
          observer.error(err);
        });
      } else {
        getClientNut(observer, orgId);
      }
    });
  }

  public getClientCGMTimeframeData(orgId: string, userId: string, start: string, end: string, forcePull: boolean = false): Observable<any> {
    const getClientCGM = (observer, oid) => {
      this.postRequest('patient/pull-patient-items', {
        organizationId: oid,
        patientId: userId,
        dataType: 'ambulatory',
        dateRange: {
          start: start,
          end: end
        }
      }).subscribe(val => {
        if (val == null) {
          observer.error();
        } else if (val.hasOwnProperty('errorMessage')) {
          observer.error(val);
        } else {
          this.clientDayData.ambulatory[userId + start + end] = val;
          observer.next(val);
          observer.complete();
        }
      }, err => {
        observer.error(err);
      });
    };

    return new Observable(observer => {
      if (this.clientDayData.ambulatory.hasOwnProperty(userId + start + end) && !forcePull) {
        observer.next(this.clientDayData.ambulatory[userId + start + end]);
      } else if (orgId === '') {
        this.getProviderData().subscribe(() => {
          getClientCGM(observer, this.orgIdDefault);
        }, err => {
          observer.error(err);
        });
      } else {
        getClientCGM(observer, orgId);
      }
    });
  }

  public getProviderData(forcePull: boolean = false): Observable<any> {
    return new Observable(observer => {
      // checks for cached data
      if (this.providerData && !forcePull && !this.changeFlag.providerData) {
        observer.next(this.providerData);
      } else {
        this.postRequest('user/pull-user-data').subscribe(val => {
          if (val == null) {
            observer.error();
          } else if (val.hasOwnProperty('errorMessage')) {
            observer.error(val);
          } else {
            this.orgIdDefault = val.organizations[0].organizationId;
            this.changeFlag.providerData = false;
            this.providerData = val;
            /*this.timeOut.providerData = setTimeout(() => {
              this.providerData = {};
            }, 300000);*/
            observer.next(val);
            observer.complete();
          }
        }, err => {
          observer.error(err);
        });
      }
    });
  }

  public postProviderData(firstName: string, lastName: string, title: string): Observable<any> {
    return this.postRequest('user/push-user-data', {
      firstName: firstName,
      lastName: lastName,
      title: title
    });
  }

  public postStarChange(userId: string, isStarred: boolean): Observable<any> {
    let method;
    if (isStarred) {
      method = 'add';
    } else {
      method = 'remove';
    }
    this.changeFlag.providerData = true;
    return this.postRequest('patient/patient-star', {
      method: method,
      patientId: userId
    });
  }

  public getOrganizationData(orgId: string = this.orgIdDefault, forcePull: boolean = false): Observable<any> {
    const getOrgData = (oid, observer) => {
      this.postRequest('organization/pull-organization-data', {
        organizationId: oid
      }).subscribe(val => {
        if (val.hasOwnProperty('errorMessage')) {
          observer.error(val);
        } else {
          this.organizationData = val;
          /*this.timeOut.organizationData = setTimeout(() => {
            this.organizationData = {};
          }, 300000);*/
          observer.next(val);
          observer.complete();
        }
      }, err => {
        observer.error(err);
      });
    };

    return new Observable(observer => {
      // checks for cached data
      if (this.organizationData && !forcePull) {
        observer.next(this.organizationData);
      } else if (orgId === '') {
        this.getProviderData().subscribe(prov => {
          getOrgData(this.orgIdDefault, observer);
        }, err => {
          observer.error(err);
        });
      } else {
        getOrgData(orgId, observer);
      }
    });
  }

  public group(groupName: string, method: string, patientId: string = '', forcePull: boolean = false): Observable<any> {
    // method options: create-group, destroy-group, add, remove
    return new Observable(observer => {
      // checks for cached data
      if (this.groups.hasOwnProperty(groupName) && !this.changeFlag.group[groupName] && !forcePull && method === 'pull-group') {
        observer.next(this.groups[groupName]);
      } else {
        this.postRequest('patient/patient-group', {
          groupName: groupName,
          method: method,
          patientId: patientId
        }).subscribe(val => {
          if (val && val.hasOwnProperty('errorMessage')) {
            observer.error(val);
          } else {
            if (method === 'pull-group') {
              this.groups[groupName] = val;
              this.changeFlag.group[groupName] = false;
              /*this.timeOut.groups[groupName] = setTimeout(() => {
                delete this.groups[groupName];
              }, 300000);*/
            } else if (method === 'add') {
              this.changeFlag.group[groupName] = true;
            } else if (method === 'destroy-group' || method === 'create-group') {
              this.changeFlag.providerData = true;
            }
            observer.next(val);
            observer.complete();
          }
        }, err => {
          observer.error(err);
        });
      }
    });
  }

  public note(args: any): Observable<any> {
    const params: any = {
      noteType: args.noteType,
      method: args.method
    };

    if (args.hasOwnProperty('text')) {
      params.text = args.text;
    }
    if (args.hasOwnProperty('patientId')) {
      params.patientId = args.patientId;
    }
    if (args.hasOwnProperty('date')) {
      params.date = args.date;
    }
    if (args.hasOwnProperty('noteId')) {
      params.noteId = args.noteId;
    }
    if (args.hasOwnProperty('groupName')) {
      params.groupName = args.groupName;
    }

    return new Observable(observer => {
      this.postRequest('patient/patient-notes', params).subscribe(val => {
        if (val.hasOwnProperty('errorMessage')) {
          observer.error(val);
        } else {
          observer.next(val);
          observer.complete();
        }
      }, err => observer.error(err));
    });
  }

  // Makes acutal https requests
  private postRequest(endpoint, body = {}): Observable<any> {
    return new Observable(observer => {
      this.auth.getAccessToken().subscribe(token => {
        this.http.post(BASE_API_URL + endpoint, JSON.stringify(body), {
          headers: new HttpHeaders().set('authenticationToken', token).set('Content-Type', 'application/json') // TODO: send cognito token
        }).subscribe({
          next: res => {
            observer.next(res);
            observer.complete();
          },
          error: err => observer.error(err)
        });
      }, err => observer.error(err));
    });
  }

  private getRequest(endpoint): Observable<any> {
    return new Observable(observer => {
      this.auth.getAccessToken().subscribe(token => {
        this.http.get(BASE_API_URL + endpoint, {
          headers: new HttpHeaders().set('authenticationToken', token).set('Content-Type', 'application/json') // TODO: send cognito token
        }).subscribe({
          next: res => {
            observer.next(res);
            observer.complete();
          },
          error: err => observer.error(err)
        });
      }, err => observer.error(err));
    });
  }
}
