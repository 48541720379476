import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientHttpsService } from '../../../services/client-https.service';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.css']
})
export class CompleteComponent implements OnInit {
  public userCredentials = {
    firstName: '',
    lastName: '',
    title: 'Title'
  };

  public errors = {
    missingFN: false,
    missingLN: false,
    missingTitle: false
  };

  constructor(private router: Router, private client: ClientHttpsService) { }

  ngOnInit() {
  }

  get buttonColor(): string {
    if (this.userCredentials.firstName.length !== 0
      && this.userCredentials.lastName.length !== 0 && this.userCredentials.title !== 'Title') {
      return '#35C4A8';
    } else {
      return 'lightgrey';
    }
  }

  public checkForm(): void {
    for (const error in this.errors) {
      this.errors[error] = false;
    }
    if (this.userCredentials.firstName.length === 0) {
      this.errors.missingFN = true;
    }
    if (this.userCredentials.lastName.length === 0) {
      this.errors.missingLN = true;
    }
  }

  public submit(): void {
    this.checkForm();
    console.log(this.userCredentials);
    if (this.userCredentials.title === 'Title') {
      this.errors.missingTitle = true;
    } else if (this.userCredentials.firstName.length !== 0 && this.userCredentials.lastName.length !== 0
      && this.userCredentials.title !== 'Title') {
      this.client.postProviderData(this.userCredentials.firstName, this.userCredentials.lastName,
        this.userCredentials.title).subscribe(val => {
        this.router.navigateByUrl('dashboard');
      }, err => console.log(err));
    }
  }
}
