import { Component, OnChanges, EventEmitter, ElementRef, Input, Output, ViewChild, HostListener } from '@angular/core';
import { ClientHttpsService } from '../../../../services/client-https.service';
import { GRAPH_HEIGHTS, MILLI_TO_DAY, COLOR_STYLES, CAL_PER_NUT_GRAM } from '../config';
import { Observable } from 'rxjs';
import * as d3 from 'd3';

interface Resizing {
  isResizing: boolean;
  currentInnerWidth?: number;
  timeOut?: NodeJS.Timer;
}

interface Weight {
  weight: number;
  time: Date;
}

const KG_TO_LBS = 2.204623;

@Component({
  selector: 'app-duration-weight-graph',
  templateUrl: './duration-weight-graph.component.html',
  styleUrls: ['./duration-weight-graph.component.css', '../patient.component.css']
})
export class DurationWeightGraphComponent implements OnChanges {
  @Input() baseDate: Date;
  @Input() patientId: string;
  @Input() isDataLoaded: boolean;
  @Output() baseDateChangedEvent = new EventEmitter<Date>();
  @ViewChild('graph', { static: true }) graphWidth: ElementRef;

  public resizing: Resizing;
  public timeRange = 7;
  public startDate: Date;
  public endDate: Date;
  public isLoading: boolean;
  private baseY = GRAPH_HEIGHTS.main - 50;
  private maxY = 150;
  private minY = 100;
  private weights: Array<Weight> = [];

  set graphTime(date: string) {
    const fDate = new Date(date).setHours(0, 0, 0, 0);
    this.startDate = new Date(fDate - (this.timeRange * MILLI_TO_DAY));
    this.endDate = new Date(fDate);
    this.drawGraph();
  }

  get graphTime() {
    return this.endDate.toISOString();
  }

  public get isLoadingBinary() {
    if (this.isLoading) {
      return 0;
    } else {
      return 1;
    }
  }

  constructor(public client: ClientHttpsService) { }

  ngOnChanges() {
    this.resizing = { isResizing: false };
    this.isLoading = true;
    this.graphTime = this.baseDate.toISOString();

    d3.select('svg#duration-weight-graph')
      .attr('height', GRAPH_HEIGHTS.main)
      .attr('width', this.graphWidth.nativeElement.offsetWidth);

    if (this.isDataLoaded) {
      this.dataObservable.subscribe(data => {
        this.formatData(data);
        this.drawGraph();
      });
    }
  }

  private get dataObservable(): Observable<any> {
    return this.client.getClientNutTimeframeData(
      this.client.orgIdDefault,
      this.patientId,
      this.startDate.toISOString(),
      this.endDate.toISOString()
    );
  }

  private formatData(data: any): void {
    data.forEach((d: any) => {
      d.time = new Date(new Date(d.time).setHours(0, 0, 0, 0));

      if (d.type === 'weight') {
        let exists = false;
        this.weights.forEach((e: any) => {
          if (e.time.toISOString() === d.time.toISOString()) {
            e.weight = Math.round((+e.weight + +d.weight * KG_TO_LBS) / 2);
            exists = true;
          }
        });

        if (!exists) {
          d.weight = Math.round(+d.weight * KG_TO_LBS);
          this.weights.push(d);
        }

      }
    });

    if (this.weights.length > 0) {
      this.minY = Math.round(d3.min(this.weights, (d: any) => d.weight) - 10);
      this.maxY = Math.round(d3.max(this.weights, (d: any) => d.weight) + 10);
    }
  }

  /* ===== DRAW SPAGHETTI GRAPH ===== */
  public drawGraph() {
    const svg = d3.select('svg#duration-weight-graph');

    this.isLoading = true;

    /* -- remove preexisting elements -- */
    svg.selectAll('g.primary-axis')
      .remove();

    svg.selectAll('g.target-axis')
      .remove();

    const xScale = d3.scaleTime()
      .domain([this.startDate, this.endDate])
      .range([100, this.graphWidth.nativeElement.offsetWidth - 100]);

    const yScale = d3.scaleLinear()
      .domain([this.minY, this.maxY])
      .range([this.baseY, 30]);

    const xAxis = d3.axisBottom(xScale)
      .ticks(5)
      .tickFormat((d: Date) => `${d.getMonth()}/${d.getDate()}`);

    const yAxis = d3.axisLeft(yScale)
      .ticks(7);

    /* -- Draws x-axis ticks -- */
    svg.append('g')
      .attr('transform', `translate(0, ${GRAPH_HEIGHTS.main - 35})`)
      .classed('primary-axis', true)
      .classed('x-axis', true)
      .call(xAxis);

    /* -- Draw y-axis ticks -- */
    svg.append('g')
      .attr('transform', 'translate(75, 0)')
      .classed('primary-axis', true)
      .classed('y-axis', true)
      .call(yAxis);

    /* -- Format Axes-- */
    svg.selectAll('.primary-axis')
      .selectAll('path')
      .remove();

    svg.selectAll('.primary-axis')
      .selectAll('g')
      .selectAll('line')
      .remove();

    svg.selectAll('.primary-axis')
      .selectAll('g')
      .selectAll('text')
      .attr('fill', COLOR_STYLES.graph)
      .attr('dy', '.2em')
      .attr('style', 'font-size: 1.4em;');

    svg.selectAll('.target-axis') // Target Axes
      .selectAll('path')
      .remove();

    svg.selectAll('.target-axis') // Target Axes
      .selectAll('g')
      .selectAll('line')
      .remove();

    svg.selectAll('.target-axis') // Target Axes
      .selectAll('g')
      .selectAll('text')
      .attr('fill', COLOR_STYLES.bloodGlucoseHighlightText)
      .attr('dy', '.2em')
      .attr('style', 'font-size: 1.4em;');

    /* -- Horizontal graph lines -- */
    svg.selectAll('g.y-axis g.tick')
      .append('line')
      .classed('grid-line', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
      .attr('y2', 0)
      .attr('stroke', COLOR_STYLES.graph);

    svg.selectAll('g.target-axis g.tick') // Target grid lines
      .append('line')
      .classed('grid-target-line', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
      .attr('y2', 0)
      .attr('stroke', COLOR_STYLES.bloodGlucoseHighlightText)
      .attr('stroke-dasharray', '10,10');

    this.drawWeightLines(svg, yScale, xScale);

    svg.selectAll('circle').raise();

    this.isLoading = false;
  }

  private drawWeightLines(svg, yScale, xScale): void {
    svg.selectAll('circle.weight-dot')
      .data(this.weights)
      .join('circle')
      .classed('weight-dot', true)
      .attr('fill', COLOR_STYLES.weight)
      .attr('cx', (d: any) => xScale(d.time))
      .attr('cy', (d: any) => yScale(d.weight))
      .attr('r', '6px');
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    if (this.resizing.currentInnerWidth !== window.innerWidth) {
      this.resizing.isResizing = true;
      if (this.resizing.hasOwnProperty('timeOut')) {
        clearTimeout(this.resizing.timeOut);
      }

      // Create timeout for 300ms
      this.resizing.timeOut = setTimeout(() => {
        if (this.resizing.currentInnerWidth === window.innerWidth) {
          this.resizing.isResizing = false;
        } else {
          this.resizing.currentInnerWidth = window.innerWidth;
          this.resizing.isResizing = false;
          this.drawGraph();
        }
        delete this.resizing.timeOut;
      }, 300);
    }
  }
}
