import { Component, OnInit, Input } from '@angular/core';

const enum sourceText {
  manual = 'Manual Patient Entry',
  cgm = 'Continuous Glucose Monitor'
}

@Component({
  selector: 'app-graph-details',
  templateUrl: './graph-details.component.html',
  styleUrls: ['./graph-details.component.css']
})
export class GraphDetailsComponent implements OnInit {
  @Input() whenRefreshed: Date;
  @Input() isUsingCGM: boolean;

  get bloodGlucoseSource(): string {
    if (this.isUsingCGM) {
      return sourceText.cgm;
    } else {
      return sourceText.manual;
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
