import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BLOOD_GLUCOSE_TARGET, COLOR_STYLES, GRAPH_HEIGHTS, MILLI_TO_DAY } from '../config';
import { ClientHttpsService } from '../../../../services/client-https.service';
import { Observable } from 'rxjs';
import * as d3 from 'd3';

interface Visible {
  foodDetails: boolean;
}

interface Resizing {
  isResizing: boolean;
  currentInnerWidth?: number;
  timeOut?: NodeJS.Timer;
}

@Component({
  selector: 'app-daily-graph',
  templateUrl: './daily-graph.component.html',
  styleUrls: ['./daily-graph.component.css', '../patient.component.css']
})
export class DailyGraphComponent implements OnChanges {
  @Input() baseDate: Date;
  @Input() patientId: string;
  @Input() isDataLoaded: boolean;
  @Output() baseDateChangedEvent = new EventEmitter<Date>();
  @ViewChild('graph', { static: true }) graphWidth: ElementRef;

  private yRange = {
    high: 200,
    low: 50
  };

  public resizing: Resizing;
  public isLoading: boolean;
  public isVisible: Visible;
  public foodDetails: any;
  public patientData = {
    bloodGlucose: [],
    insulin: [],
    food: [],
    cgm: [],
    isCgm: null,
  };

  public set graphTime(date: Date) {
    this.baseDate = new Date(new Date(date).setHours(0, 0, 0, 0));
    this.baseDateChangedEvent.emit(this.baseDate);
    this.drawGraph();
  }

  public get graphTime(): Date {
    return this.baseDate;
  }

  // For opacity styles
  public get isLoadingBinary(): number {
    if (this.isLoading) {
      return 0;
    } else {
      return 1;
    }
  }

  // Changes time frame for daily graph
  public changeDailyDate(numberChange?: number): void {
    if (numberChange) {
      this.graphTime = new Date(this.graphTime.getTime() + numberChange * MILLI_TO_DAY);
      this.drawGraph();
    }
  }

  constructor(private client: ClientHttpsService) { }

  ngOnChanges() {
    this.resizing = { isResizing: false };

    this.isLoading = true;

    this.isVisible = {
      foodDetails: false,
    };

    d3.select('#daily-graph')
      .attr('height', GRAPH_HEIGHTS.svg)
      .attr('width', this.graphWidth.nativeElement.offsetWidth);

    if (this.isDataLoaded) {
      this.dataObservable.subscribe(data => {
        this.formatData(data);
        this.drawGraph();
      }, (err) => {
        console.log(err);
        this.isLoading = false;
      });
    }
  }

  private get dataObservable(): Observable<any> {
    return this.client.getClientDayData(
      this.client.orgIdDefault,
      this.patientId,
      this.graphTime.toISOString()
    );
  }

  private formatData(data: any): void {
    this.patientData.isCgm = data.isCGM;
    this.patientData.bloodGlucose = [];
    this.patientData.insulin = [];
    this.patientData.food = [];
    this.patientData.cgm = [];

    const manualEntries = data.manual.sort((a: any, b: any) => {
      return +new Date(a.time) - +new Date(b.time);
    });

    // categorize manual entries
    manualEntries.forEach((d: any) => {
      if (new Date(d.time).setHours(0, 0, 0, 0) === this.graphTime.getTime()) {
        if (d.type === 'blood-glucose') {
          this.patientData.bloodGlucose.push(JSON.parse(JSON.stringify(d)));
        } else if (d.type === 'insulin') {
          this.patientData.insulin.push(JSON.parse(JSON.stringify(d)));
        } else if (d.type === 'food') {
          this.patientData.food.push(JSON.parse(JSON.stringify(d)));
        }
      }
    });

    // sets y range based on day's data
    if (this.patientData.isCgm) {
      this.patientData.cgm = data.cgm;
      if (this.patientData.cgm.length > 0) {
        const max = Math.round(d3.max(this.patientData.cgm, (d: any) => Number(d.value)) + 10);
        const min = Math.round(d3.min(this.patientData.cgm, (d: any) => Number(d.value)) - 10);

        if (this.yRange.high < max) {
          this.yRange.high = max;
        }

        if (this.yRange.low > min) {
          this.yRange.low = min;
        }
      }
    } else {
      this.patientData.bloodGlucose.forEach((d: any) => {
        if (d.value >= this.yRange.high) {
          this.yRange.high = Math.ceil(d.value / 10) * 10 + 10;
        } else if (d.value <= this.yRange.low) {
          this.yRange.low = Math.ceil(d.value / 10) * 10 - 10;
        }
      });
    }

     // Checks for foods with close times
    // Combines foods within 45 minutes
    for (let i = 0; i < this.patientData.food.length; i++) {
      if (i > 0) {
        const timeDifference = new Date(
          this.patientData.food[i].time).getTime() - new Date(this.patientData.food[i - 1].time).getTime();

        if (timeDifference / 1000 / 60 < 45) {  // within 45 minutes
          if (this.patientData.food[i - 1].hasOwnProperty('foods') && this.patientData.food[i - 1].foods.length > 0) {
            // previous food entry has multiple foods
            const timeframeDifference = new Date(
              this.patientData.food[i].time).getTime()
              - new Date(this.patientData.food[i - 1].timeframe.start)
                .getTime();

            this.patientData.food[i - 1].foods.push(this.patientData.food[i]);
            this.patientData.food[i - 1].timeframe.end = this.patientData.food[i].time;
            this.patientData.food[i - 1].time = new Date(
              new Date(this.patientData.food[i - 1].time).getTime()
              + (timeframeDifference / 2))
              .toISOString();
          } else {
            // previous food entry does not have multiple foods
            this.patientData.food[i - 1].foods = [
              JSON.parse(JSON.stringify(this.patientData.food[i - 1])),
              JSON.parse(JSON.stringify(this.patientData.food[i]))
            ];

            this.patientData.food[i - 1].timeframe = {
              start: this.patientData.food[i - 1].time,
              end: this.patientData.food[i].time
            };

            this.patientData.food[i - 1].time = new Date(
              new Date(this.patientData.food[i - 1].time).getTime()
              + (timeDifference / 2))
              .toISOString();
          }

          for (const nut in this.patientData.food[i - 1].nutrients) {
            if (nut) {
              this.patientData.food[i - 1].nutrients[nut] = Number(this.patientData.food[i - 1].nutrients[nut])
                + Number(this.patientData.food[i].nutrients[nut]);
            }
          }

          this.patientData.food.splice(i, 1);   // removes current entry from array
          this.patientData.food[i - 1].isMultiple = true;
          i--;  // because current item is deleted
        } else {
          this.patientData.food[i].isMultiple = false;
        }
      }
    }
  }

  /* ===== DRAW DAILY GRAPH ===== */
  public drawGraph(): void {
    console.log(this.patientData);

    let xScale, yScale;
    const svg = d3.select('svg#daily-graph');
    const bgTarget = BLOOD_GLUCOSE_TARGET; // for scoping purposes

    this.isLoading = true;

    // used to calculate y axis at given time on the blood glucose chart
    // returns y coordinate
    const calcPointY = (time: string) => {
      for (let i = 0; i < this.patientData.bloodGlucose.length; i++) {
        const d = this.patientData.bloodGlucose[i];
        if (new Date(time).getTime() < new Date(d.time).getTime()) {
          if (i !== 0) {
            // const x = xScale(new Date(time));
            const leftY = yScale(this.patientData.bloodGlucose[i - 1].value);
            const leftX = xScale(new Date(this.patientData.bloodGlucose[i - 1].time));
            const ratio = (yScale(d.value) - leftY) / (xScale(new Date(d.time)) - leftX);
            return leftY + (ratio * (xScale(new Date(time)) - leftX));
          } else {
            // if before all blood glucose points
            return 200;
          }
        }
      }
      // if after all blood glucose points
      return 200;
    };

    /* -- remove preexisting elements -- */
    svg.selectAll('g.primary-axis')
      .remove();

    svg.selectAll('g.target-axis')
      .remove();

    svg.selectAll('#cgm-line')
      .remove();

    svg.selectAll('g.bg-lines')
      .remove();

    xScale = d3.scaleTime()
      .domain([this.graphTime, new Date(this.graphTime.getTime() + MILLI_TO_DAY)]) // Time frame of one day
      .range([75, this.graphWidth.nativeElement.offsetWidth - 75]);

    yScale = d3.scaleLinear()
      .domain([this.yRange.low, this.yRange.high])
      .range([GRAPH_HEIGHTS.svg - 80, 0 + 30]);

    const xAxis = d3.axisBottom(xScale)
      .ticks(5)
      .tickFormat((d: Date) => d.getHours() + ':00');

    const yAxisTarget = d3.axisLeft(yScale)
      .tickValues([bgTarget.low, bgTarget.high]);

    const yAxis = d3.axisLeft(yScale)
      .ticks(7);

    const cgmLine = d3.line()
      .x((d: any) => xScale(new Date(d.systemTime.replace('T', 'UTC'))))
      .y((d: any) => yScale(d.value))
      .curve(d3.curveCardinal);

    /* -- Draws target y-axis ticks -- */
    svg.append('g')
      .attr('transform', 'translate(75, 0)')
      .classed('target-axis', true)
      .call(yAxisTarget);

    /* -- Draws x-axis ticks -- */
    svg.append('g')
      .attr('transform', `translate(0, ${GRAPH_HEIGHTS.svg - 35})`)
      .classed('primary-axis', true)
      .classed('x-axis', true)
      .call(xAxis);

    /* -- Draw y-axis ticks -- */
    svg.append('g')
      .attr('transform', 'translate(75, 0)')
      .classed('primary-axis', true)
      .classed('y-axis', true)
      .call(yAxis);

    /* -- Format Axes-- */
    svg.selectAll('.primary-axis')
      .selectAll('path')
      .remove();

    svg.selectAll('.primary-axis')
      .selectAll('g')
      .selectAll('line')
      .remove();

    svg.selectAll('.primary-axis')
      .selectAll('g')
      .selectAll('text')
      .attr('fill', COLOR_STYLES.graph)
      .attr('dy', '.2em')
      .attr('style', 'font-size: 1.4em;');

    svg.selectAll('g.y-axis g.tick') // Remove axes conflicting with target axes
      .each(function (d) {
        if ((d > bgTarget.low - 10 && d < bgTarget.low + 10)
          || (d > bgTarget.high - 10 && d < bgTarget.high + 10)) {
          d3.select(this).remove();
        }
      });

    svg.selectAll('.target-axis') // Target Axes
      .selectAll('path')
      .remove();

    svg.selectAll('.target-axis') // Target Axes
      .selectAll('g')
      .selectAll('line')
      .remove();

    svg.selectAll('.target-axis') // Target Axes
      .selectAll('g')
      .selectAll('text')
      .attr('fill', COLOR_STYLES.bloodGlucoseHighlightText)
      .attr('dy', '.2em')
      .attr('style', 'font-size: 1.4em;');

    /* -- Horizontal graph lines -- */
    svg.selectAll('g.y-axis g.tick')
      .append('line')
      .classed('grid-line', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
      .attr('y2', 0)
      .attr('stroke', COLOR_STYLES.graph);

    svg.selectAll('g.target-axis g.tick') // Target grid lines
      .append('line')
      .classed('grid-target-line', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
      .attr('y2', 0)
      .attr('stroke', COLOR_STYLES.bloodGlucoseHighlightText)
      .attr('stroke-dasharray', '10,10');

    // d3.selectAll('circle#cgm-circle').remove();

    if (this.patientData.isCgm) {
      svg.append('path')
        .attr('id', 'cgm-line')
        .attr('d', cgmLine(this.patientData.cgm))
        .attr('stroke', COLOR_STYLES.bloodGlucose)
        .attr('stroke-width', '2')
        .attr('fill', 'none');

    } else {
      /* -- Graphing lines between blood glucose points -- */
      svg.append('g')
        .classed('bg-lines', true);

      svg.select('g.bg-lines')
        .selectAll('line')
        .remove();

      this.patientData.bloodGlucose.forEach((d, i) => {
        // Exit operation on final data point
        if (i < this.patientData.bloodGlucose.length - 1) {
          svg.select('g.bg-lines')
            .append('line')
            .attr('x1', xScale(new Date(d.time)))
            .attr('y1', yScale(d.value))
            .attr('x2', xScale(new Date(this.patientData.bloodGlucose[i + 1].time)))
            .attr('y2', yScale(this.patientData.bloodGlucose[i + 1].value))
            .attr('stroke', COLOR_STYLES.bloodGlucose)
            .attr('stroke-dasharray', '5,5');
        }
      });

      /* -- Graphing blood glucose points -- */
      const bgCirclesSelection = svg.selectAll('g.daily-bg-dot')
        .data(this.patientData.bloodGlucose)
        .attr('transform', (d: any) => `translate(${xScale(new Date(d.time))}, ${yScale(d.value)})`);

      bgCirclesSelection.enter()
        .append('g')
        .classed('daily', true)
        .classed('daily-bg-dot', true)
        .classed('selectable', true)
        .on('mouseover', function (d: any) {
          d3.select(this)
            .append('text')
            .text(d.value + ' mg/dL')
            .attr('style', 'text-shadow: black 0 0 5px')
            .attr('fill', 'white')
            .attr('text-anchor', 'middle')
            .attr('y', '-15px');
        })
        .on('mouseout', function (d: any) {
          d3.select(this)
            .selectAll('text')
            .remove();
        })
        .attr('transform', (d: any) => `translate(${xScale(new Date(d.time))}, ${yScale(d.value)})`)
        // Dots increase in radius on mouse hover
        .append('circle')
        .attr('r', 4)
        .attr('fill', COLOR_STYLES.bloodGlucose)
        .on('mouseover', function (d: any) {
          d3.select(this)
            .transition()
            .attr('r', '6');
        })
        .on('mouseout', function (d: any) {
          d3.select(this)
            .transition()
            .attr('r', '4');
        });

      bgCirclesSelection.exit()
        .remove();
    }

    /* -- Graphing food points -- */
    const foodSelection = svg.selectAll('g.daily-food')
      .data(this.patientData.food)
      .attr('transform', (d: any) => `translate(${xScale(new Date(d.time))}, ${GRAPH_HEIGHTS.svg - 125})`) // calcPointY(d.time)
      .each(function (d: any) {
        const selectedThis = d3.select(this);
        const minR = 5;
        const circleRadii = {
          fat: Math.sqrt(d.nutrients.fat / Math.PI) * 5,
          protein: Math.sqrt(d.nutrients.protein / Math.PI) * 5,
          carbohydrate: Math.sqrt(d.nutrients.carbohydrate / Math.PI) * 5
        };

        if (circleRadii.fat < minR && circleRadii.fat > 0) {
          circleRadii.fat = minR;
        }

        if (circleRadii.protein < minR && circleRadii.protein > 0) {
          circleRadii.protein = minR;
        }

        if (circleRadii.carbohydrate < minR && circleRadii.carbohydrate > 0) {
          circleRadii.carbohydrate = minR;
        }

        // fat cicle - top
        selectedThis.select('circle.fat')
          .classed('fat', true)
          .attr('r', 0)
          .attr('cy', -circleRadii.carbohydrate - circleRadii.fat)
          .attr('fill', COLOR_STYLES.food.fat)
          .attr('opacity', '.8')
          .transition()
          .duration(100)
          .attr('r', circleRadii.fat);

        selectedThis.select('circle.carbohydrate')
          .attr('r', 0)
          .attr('fill', COLOR_STYLES.food.carbohydrate)
          .attr('opacity', '.8')
          .transition()
          .duration(100)
          .attr('r', circleRadii.carbohydrate);

        selectedThis.select('circle.protein')
          .attr('r', circleRadii.protein)
          .attr('cy', circleRadii.carbohydrate + circleRadii.protein)
          .attr('fill', COLOR_STYLES.food.protein)
          .attr('opacity', '.8')
          .transition()
          .duration(100)
          .attr('r', circleRadii.protein);
      });

    foodSelection.enter()
      .append('g')
      .classed('daily', true)
      .classed('daily-food', true)
      .classed('selectable', true)
      .attr('transform', (d: any) => `translate(${xScale(new Date(d.time))}, ${GRAPH_HEIGHTS.svg - 125})`) // calcPointY(d.time)
      .on('mouseover', function (d: any) {
        let maxYDisplacement = Math.sqrt(d.nutrients.carbohydrate / Math.PI) * 10;
        if (Math.sqrt(d.nutrients.protein / Math.PI) * 10 > maxYDisplacement) {
          maxYDisplacement = Math.sqrt(d.nutrients.protein / Math.PI) * 10;
        }
        if (Math.sqrt(d.nutrients.fat / Math.PI) * 10 > maxYDisplacement) {
          maxYDisplacement = Math.sqrt(d.nutrients.fat / Math.PI) * 10;
        }
        maxYDisplacement += 10;

        // carbohydrate
        d3.select(this)
          .append('text')
          .text(Number(d.nutrients.carbohydrate).toFixed(1) + ' g carbohydrate')
          .attr('style', 'text-shadow: black 0 0 5px')
          .attr('fill', 'white')
          .attr('alignment-baseline', 'middle')
          .attr('x', maxYDisplacement);

        // fat
        d3.select(this)
          .append('text')
          .text(Number(d.nutrients.fat).toFixed(1) + ' g fat')
          .attr('style', 'text-shadow: black 0 0 5px')
          .attr('fill', 'white')
          .attr('alignment-baseline', 'middle')
          .attr('y', '-1.2em')
          .attr('x', maxYDisplacement);

        // protein
        d3.select(this)
          .append('text')
          .text(Number(d.nutrients.protein).toFixed(1) + ' g protein')
          .attr('style', 'text-shadow: black 0 0 5px')
          .attr('fill', 'white')
          .attr('alignment-baseline', 'middle')
          .attr('y', '1.2em')
          .attr('x', maxYDisplacement);

        d3.select(this).raise();
      })
      .on('mouseout', function (d) {
        d3.select(this)
          .selectAll('text')
          .remove();
      })
      .on('click', d => {
        this.isVisible.foodDetails = true;
        this.foodDetails = d;
      })
      .each(function (d: any) {
        const selectedThis = d3.select(this);
        const minR = 5;
        const circleRadii = {
          fat: Math.sqrt(d.nutrients.fat / Math.PI) * 5,
          protein: Math.sqrt(d.nutrients.protein / Math.PI) * 5,
          carbohydrate: Math.sqrt(d.nutrients.carbohydrate / Math.PI) * 5
        };

        if (circleRadii.fat < minR && circleRadii.fat > 0) {
          circleRadii.fat = minR;
        }

        if (circleRadii.protein < minR && circleRadii.protein > 0) {
          circleRadii.protein = minR;
        }

        if (circleRadii.carbohydrate < minR && circleRadii.carbohydrate > 0) {
          circleRadii.carbohydrate = minR;
        }

        // fat cicle - top
        selectedThis.append('circle')
          .classed('fat', true)
          .attr('r', () => circleRadii.fat)
          .attr('cy', () => -circleRadii.carbohydrate - circleRadii.fat)
          .attr('fill', COLOR_STYLES.food.fat)
          .attr('opacity', '.8');

        selectedThis.append('circle')
          .classed('carbohydrate', true)
          .attr('r', () => circleRadii.carbohydrate)
          .attr('fill', COLOR_STYLES.food.carbohydrate)
          .attr('opacity', '.8');

        selectedThis.append('circle')
          .classed('protein', true)
          .attr('r', () => circleRadii.protein)
          .attr('cy', () => circleRadii.carbohydrate + circleRadii.protein)
          .attr('fill', COLOR_STYLES.food.protein)
          .attr('opacity', '.8');
      });

    foodSelection.exit()
      .remove();

    /* -- Graphing insulin points -- */
    const insulinSelection = svg.selectAll('g.daily-insulin')
      .data(this.patientData.insulin)
      .attr('transform', (d: any) => `translate(${xScale(new Date(d.time))}, ${GRAPH_HEIGHTS.svg - 50})`);

    insulinSelection.enter()
      .append('g')
      .classed('daily', true)
      .classed('daily-insulin', true)
      .classed('selectable', true)
      //    .attr('transform', function(d) { return `translate(${xScale(new Date(d.time))}, ${calcPointY(d.time)})`; })
      .attr('transform', (d: any) => `translate(${xScale(new Date(d.time))}, ${GRAPH_HEIGHTS.svg - 50})`)
      .on('mouseover', function (d: any) {
        d3.select(this)
          .append('text')
          .text(d.value + ' units')
          .attr('style', 'text-shadow: black 0 0 5px')
          .attr('fill', 'white')
          .attr('text-anchor', 'middle')
          .attr('y', () => {
            if (d.value <= 5) {
              return -30;
            } else {
              return -(d.value * 4 + 10);
            }
          });

        d3.select(this).raise();
      })
      .on('mouseout', function (d) {
        d3.select(this)
          .selectAll('text')
          .remove();
      })
      /*   .append('circle')
            .attr('r', function(d) {
              if (d.value < 5) return 6;
              return d.value * 1.2;
            })
            .attr('fill', COLOR_STYLES.insulin)
            .attr('opacity', '.8'); */
      .append('rect')
      .attr('x', '-5')
      .attr('width', '10')
      .attr('y', function (d: any) {
        if (d.value <= 5) {
          return -20;
        } else {
          return -(d.value * 4);
        }
      })
      .attr('height', function (d: any) {
        if (d.value <= 5) {
          return 20;
        } else {
          return d.value * 4;
        }
      })
      .attr('fill', COLOR_STYLES.insulin);

    insulinSelection.exit()
      .remove();

    d3.selectAll('.daily')
      .raise();

    this.isLoading = false;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    if (this.resizing.currentInnerWidth !== window.innerWidth) {
      this.resizing.isResizing = true;
      if (this.resizing.hasOwnProperty('timeOut')) {
        clearTimeout(this.resizing.timeOut);
      }

      // Create timeout for 300ms
      this.resizing.timeOut = setTimeout(() => {
        if (this.resizing.currentInnerWidth === window.innerWidth) {
          this.resizing.isResizing = false;
        } else {
          this.resizing.currentInnerWidth = window.innerWidth;
          this.resizing.isResizing = false;
          this.drawGraph();
        }
        delete this.resizing.timeOut;
      }, 300);
    }
  }
}
