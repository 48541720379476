import { Component, OnChanges, Input, Output, ViewChild, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { MILLI_TO_DAY, COLOR_STYLES, BLOOD_GLUCOSE_TARGET, GRAPH_HEIGHTS } from '../config';
import { ClientHttpsService } from '../../../../services/client-https.service';
import * as d3 from 'd3';
import { Observable } from 'rxjs';

interface Visible {
  cgmConnection: boolean;
}

interface Resizing {
  isResizing: boolean;
  currentInnerWidth?: number;
  timeOut?: NodeJS.Timer;
}

@Component({
  selector: 'app-heat-map-graph',
  templateUrl: './heat-map-graph.component.html',
  styleUrls: ['./heat-map-graph.component.css', '../patient.component.css']
})
export class HeatMapGraphComponent implements OnChanges {
  @Input() baseDate: Date;
  @Input() patientId: string;
  @Input() isDataLoaded: boolean;
  @Output() baseDateChangedEvent = new EventEmitter<Date>();
  @ViewChild('graph', { static: true }) graphWidth: ElementRef;

  public isVisible: Visible = {
    cgmConnection: false
  };

  public resizing: Resizing;
  public timeRange = 30;
  public startDate: Date;
  public endDate: Date;
  public isLoading: boolean;
  public patientData = {
    bloodGlucose: []
  };

  set graphTime(date: string) {
    const fDate = new Date(date).setHours(0, 0, 0, 0);
    this.startDate = new Date(fDate - (this.timeRange * MILLI_TO_DAY));
    this.endDate = new Date(fDate);
    this.drawGraph();
  }

  get graphTime() {
    return this.endDate.toISOString();
  }

  public get isLoadingBinary() {
    if (this.isLoading) {
      return 0;
    } else {
      return 1;
    }
  }

  private get nutDataObservable(): Observable<any> {
    return this.client.getClientNutTimeframeData(
      this.client.orgIdDefault,
      this.patientId,
      this.startDate.toISOString(),
      this.endDate.toISOString()
    );
  }

  private get bgDataObservable(): Observable<any> {
    return this.client.getClientCGMTimeframeData(
      this.client.orgIdDefault,
      this.patientId,
      this.startDate.toISOString(),
      this.endDate.toISOString()
    );
  }

  constructor(private client: ClientHttpsService) { }

  ngOnChanges() {
    this.resizing = { isResizing: false };
    this.isLoading = true;
    this.graphTime = this.baseDate.toISOString();

    d3.select('#heat-map-graph')
      .attr('height', GRAPH_HEIGHTS.svg)
      .attr('width', this.graphWidth.nativeElement.offsetWidth);

    this.drawGraph();
  }

  /* ===== DRAW HEAT MAP & AMBULATORY ===== */
  public drawGraph(): void {
    const svg = d3.select('svg#heat-map-graph');
    const bgTarget = BLOOD_GLUCOSE_TARGET; // for scoping purposes
    const yRange = {  // default and minimum y range
      high: 200,
      low: 50
    };

    this.isLoading = true;

    /* -- remove preexisting elements -- */
    svg.select('text#hover-text')
      .remove();

    svg.selectAll('.heat-map')
      .remove();

    svg.selectAll('.ambulatory')
      .remove();

    svg.selectAll('g.primary-axis')
      .remove();

    svg.selectAll('g.target-axis')
      .remove();

    // Draws heat map
    this.nutDataObservable.subscribe(val => {
      const hourlyNut = {},         // Total number of nutrients by hour
        hourlyNutAvg = {},      // Average number of nutrients by hour
        dailyNutExists = [],    // Days nutrients were recorded
        hourlyExer = {},
        hourlyExerAvg = {},
        dailyExerExists = [];

      const maxValues = {           // Maximum value for macronutrients for color scale
        carbohydrate: 0,
        fat: 0,
        protein: 0,
        exercise: 0
      };

      val.forEach(d => {
        const hour = new Date(d.time).getHours();

        if (d.type === 'food') {
          if (hourlyNut.hasOwnProperty(hour)) {
            hourlyNut[hour].carbohydrate += +d.nutritionFacts.carbohydrate;
            hourlyNut[hour].protein += +d.nutritionFacts.protein;
            hourlyNut[hour].fat += +d.nutritionFacts.fat;
          } else {
            hourlyNut[hour] = {
              carbohydrate: +d.nutritionFacts.carbohydrate,
              fat: +d.nutritionFacts.fat,
              protein: +d.nutritionFacts.protein
            };
          }

          if (!dailyNutExists.includes(new Date(d.time).setHours(0, 0, 0, 0))) {
            dailyNutExists.push(new Date(d.time).setHours(0, 0, 0, 0));
          }
        } else if (d.type === 'exercise') {
          if (hourlyExer.hasOwnProperty(hour)) {
            hourlyExer[hour] += +d.caloriesBurned;
          } else {
            hourlyExer[hour] = +d.caloriesBurned;
          }

          if (!dailyExerExists.includes(new Date(d.time).setHours(0, 0, 0, 0))) {
            dailyExerExists.push(new Date(d.time).setHours(0, 0, 0, 0));
          }
        }
      });

      for (const hour in hourlyNut) {
        if (hour) {
          // calculates average based on number of days nutrition was logged
          hourlyNutAvg[hour] = {
            carbohydrate: (hourlyNut[hour].carbohydrate / dailyNutExists.length).toFixed(1),
            fat: (hourlyNut[hour].fat / dailyNutExists.length).toFixed(1),
            protein: (hourlyNut[hour].protein / dailyNutExists.length).toFixed(1)
          };

          if (+hourlyNutAvg[hour].carbohydrate > +maxValues.carbohydrate) {
            maxValues.carbohydrate = hourlyNutAvg[hour].carbohydrate;
          }

          if (+hourlyNutAvg[hour].fat > +maxValues.fat) {
            maxValues.fat = hourlyNutAvg[hour].fat;
          }

          if (+hourlyNutAvg[hour].protein > +maxValues.protein) {
            maxValues.protein = hourlyNutAvg[hour].protein;
          }
        }
      }

      for (const hour in hourlyExer) {
        if (hour) {
          // calculates average based on number of days nutrition was logged
          hourlyExerAvg[hour] = (hourlyExer[hour] / dailyExerExists.length).toFixed(1);

          if (+hourlyExerAvg[hour] > +maxValues.exercise) {
            maxValues.exercise = hourlyExerAvg[hour];
          }
        }
      }

      /* -- Heat map scales -- */
      const fatScale = d3.scaleLinear<string>()
        .domain([0, maxValues.fat])
        .range(['#383838', COLOR_STYLES.food.fat]);

      const carbScale = d3.scaleLinear<string>()
        .domain([0, maxValues.carbohydrate])
        .range(['#383838', COLOR_STYLES.food.carbohydrate]);

      const proteinScale = d3.scaleLinear<string>()
        .domain([0, maxValues.protein])
        .range(['#383838', COLOR_STYLES.food.protein]);

      const exerciseScale = d3.scaleLinear<string>()
        .domain([0, maxValues.exercise])
        .range(['#383838', COLOR_STYLES.exercise]);

      svg.select('#heat-map')
        .remove();

      /* ###### BEGIN GRAPHING ELEMENTS ###### */
      const heatMap = svg.append('g')
        .attr('id', 'heat-map');

      // heat map hourly values
      for (let i = 0; i < 24; i++) {
        if (hourlyNutAvg.hasOwnProperty(i)) {
          const hmG = heatMap.append('g')
            .classed('heat-map', true)
            .classed('hm-hourly', true)
            .classed('hm-color', true)
            .attr('transform', `translate(${75 + i * (this.graphWidth.nativeElement.offsetWidth - 150) / 24}, 40)`);

          // graphs fat value
          hmG.append('rect')
            .attr('width', `${(this.graphWidth.nativeElement.offsetWidth - 150) / 24}px`)
            .attr('height', '30px')
            .attr('fill', fatScale(hourlyNutAvg[i].fat));

          // graphs carb value
          hmG.append('rect')
            .attr('width', `${(this.graphWidth.nativeElement.offsetWidth - 150) / 24}px`)
            .attr('height', '30px')
            .attr('y', '30px')
            .attr('fill', carbScale(hourlyNutAvg[i].carbohydrate));

          // graphs protein value
          hmG.append('rect')
            .attr('width', `${(this.graphWidth.nativeElement.offsetWidth - 150) / 24}px`)
            .attr('height', '30px')
            .attr('y', '60px')
            .attr('fill', proteinScale(hourlyNutAvg[i].protein));
        }

        if (hourlyExerAvg.hasOwnProperty(i)) {
          heatMap.append('rect')
            .attr('transform', `translate(${75 + i * (this.graphWidth.nativeElement.offsetWidth - 150) / 24}, 130)`)
            .attr('width', `${(this.graphWidth.nativeElement.offsetWidth - 150) / 24}px`)
            .attr('height', '30px')
            .attr('fill', exerciseScale(hourlyExerAvg[i]));
        }
      }

      heatMap.append('g')
        .attr('id', 'hm-lines');

      // heat map x axes
      for (let i = 0; i < 5; i++) {
        heatMap.select('#hm-lines')
          .append('line')
          .classed('hm-x-line', true)
          .attr('x1', 75)
          .attr('y1', 40 + i * 30)
          .attr('x2', this.graphWidth.nativeElement.offsetWidth - 75)
          .attr('y2', 40 + i * 30)
          .attr('stroke', COLOR_STYLES.bloodGlucoseHighlightText)
          .attr('stroke-width', '.7');
      }

      // heat map y axes
      for (let i = 0; i < 25; i++) {
        heatMap.select('#hm-lines')
          .append('line')
          .classed('hm-y-line', true)
          .attr('x1', 75 + i * (this.graphWidth.nativeElement.offsetWidth - 150) / 24)
          .attr('y1', 40)
          .attr('x2', 75 + i * (this.graphWidth.nativeElement.offsetWidth - 150) / 24)
          .attr('y2', 160)
          .attr('stroke', COLOR_STYLES.bloodGlucoseHighlightText)
          .attr('stroke-width', '.7');
      }

      /* -- Draws heat map labels -- */
      heatMap.append('text')
        .text('FAT')
        .classed('heat-map-label', true)
        .attr('transform', 'translate(66, 55)')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .attr('fill', COLOR_STYLES.graphText);

      heatMap.append('text')
        .text('CARB')
        .classed('heat-map-label', true)
        .attr('transform', 'translate(66, 85)')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .attr('fill', COLOR_STYLES.graphText);

      heatMap.append('text')
        .text('PROT')
        .classed('heat-map-label', true)
        .attr('transform', 'translate(66, 115)')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .attr('fill', COLOR_STYLES.graphText);

      heatMap.append('text')
        .text('EXER')
        .classed('heat-map-label', true)
        .attr('transform', 'translate(66, 145)')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .attr('fill', COLOR_STYLES.graphText);
    }, err => {
      console.log(err);
    });

    // Draws ambulatory graph
    // TODO: conditional ambulatory blood glucose pull
    this.bgDataObservable.subscribe((val: any) => {
      this.patientData.bloodGlucose = JSON.parse(JSON.stringify(val.sort((a, b) => {
        return +new Date(a.systemTime) - +new Date(b.systemTime);
      })));

      /*
      val.getUserEvents = val.getUserEvents.sort((a, b) => {
        return +new Date(a.systemTime) - +new Date(b.systemTime);
      });

      val.getUserEvents.forEach(d => {
        if (d.eventType === 'insulin') {
          this.patientData.CGM.insulin.push(JSON.parse(JSON.stringify(d)));  // makes deep copy
        }
      });
      */

      const timeAggregateBG = {},
        percentileBG = {
          ninetyFive: [],
          fifty: [],
          average: [],
          median: []
        };

      const bgSetMax: number = +(d3.max<number>(this.patientData.bloodGlucose, (d: any) => d.value)) + 10;
      const bgSetMin: number = +(d3.min<number>(this.patientData.bloodGlucose, (d: any) => d.value)) - 10;
      yRange.high = bgSetMax > yRange.high ? bgSetMax : yRange.high;
      yRange.low = bgSetMin > yRange.low ? bgSetMin : yRange.low;

      // iterates over all blood glucose entries
      for (let i = 0; i < this.patientData.bloodGlucose.length; i++) {
        const input = this.patientData.bloodGlucose[i];

        // Aggregate blood glucose values by time
        const timeInMilli = new Date(
          input.systemTime.replace('T', 'UTC')).getTime()
          - new Date(input.systemTime.replace('T', 'UTC'))
            .setHours(0, 0, 0, 0);

        if (timeAggregateBG.hasOwnProperty(timeInMilli)) {
          timeAggregateBG[timeInMilli].push(input.value);
        } else {
          timeAggregateBG[timeInMilli] = [input.value];
        }
      }

      // Calculate percentiles
      for (const time in timeAggregateBG) {
        if (time) {
          timeAggregateBG[time].sort((a, b) => a - b);

          // 95 percentile calculation
          percentileBG.ninetyFive.push({
            high: d3.quantile(timeAggregateBG[time], .975),
            low: d3.quantile(timeAggregateBG[time], .025),
            time: new Date(new Date().setHours(0, 0, 0, Number(time)))
          });

          // 50 percentile calculation
          percentileBG.fifty.push({
            high: d3.quantile(timeAggregateBG[time], .75),
            low: d3.quantile(timeAggregateBG[time], .25),
            time: new Date(new Date().setHours(0, 0, 0, Number(time)))
          });

          // median calculation
          percentileBG.median.push({
            value: d3.median(timeAggregateBG[time]),
            time: new Date(new Date().setHours(0, 0, 0, Number(time)))
          });

          // average calculation
          percentileBG.average.push({
            value: Math.round(d3.mean(timeAggregateBG[time])),
            time: new Date(new Date().setHours(0, 0, 0, Number(time)))
          });
        }
      }

      // Ambulatory scales
      const xScale = d3.scaleTime()
        .domain([new Date().setHours(0, 0, 0, 0), new Date(new Date().setHours(0, 0, 0, MILLI_TO_DAY))]) // Time frame of one day
        .range([75, this.graphWidth.nativeElement.offsetWidth - 75]);

      const yScale = d3.scaleLinear()
        .domain([yRange.low, yRange.high])
        .range([GRAPH_HEIGHTS.svg - 50, 160]);    // When changing height

      const xAxis = d3.axisBottom(xScale)
        .ticks(5)
        .tickFormat((d: Date) => d.getHours() + ':00');

      const yAxisTarget = d3.axisLeft(yScale)
        .tickValues([bgTarget.low, bgTarget.high]);

      const yAxis = d3.axisLeft(yScale)
        .ticks(7);

      /*svg.append('g')
        .classed('hm-hourly', true)
        .attr('id', 'avg-bg')
        .attr('transform', 'translate(75, 30)');
        */

      // Calculates average blood glucose to be displayed on the top bar.
      const hourlyBGAvg = [];
      for (let i = 0; i < 24; i++) {
        let hourlyCount = 0,
          totalBG = 0,
          avgBG: number;

        percentileBG.average.forEach(bg => {
          if (bg.time.getHours() === i) {
            totalBG += bg.value;
            hourlyCount++;
          }
        });

        try {
          avgBG = +Number(totalBG / hourlyCount).toFixed(0);
        } catch {
          avgBG = 0;
        } finally {
          hourlyBGAvg.push({
            hour: i,
            value: avgBG
          });
        }
      }

      /* -- Draws average bg labels -- */
      const avgBGValuesSelection = svg.selectAll('.hourly-avg-bg-value')
        .data(hourlyBGAvg)
        .text((d: any) => d.value)
        .attr('x', (d: any) => `${(this.graphWidth.nativeElement.offsetWidth - 150) / 48 * (d.hour * 2 + 1)}px`);

      avgBGValuesSelection.enter()
        .append('text')
        .classed('hourly-avg-bg-value', true)
        .attr('transform', 'translate(75, 30)')
        .text((d: any) => d.value)
        .attr('x', (d: any) => `${(this.graphWidth.nativeElement.offsetWidth - 150) / 48 * (d.hour * 2 + 1)}px`)
        .attr('text-anchor', 'middle')
        .attr('fill', COLOR_STYLES.graphText);

      /* -- Draws target y-axis ticks -- */
      svg.append('g')
        .attr('transform', 'translate(75, 0)')
        .classed('target-axis', true)
        .call(yAxisTarget);

      /* -- Draws x-axis ticks -- */
      svg.append('g')
        .attr('transform', `translate(0, ${GRAPH_HEIGHTS.svg - 35})`)
        .classed('primary-axis', true)
        .classed('x-axis', true)
        .call(xAxis);

      /* -- Draw y-axis ticks -- */
      svg.append('g')
        .attr('transform', 'translate(75, 0)')
        .classed('primary-axis', true)
        .classed('y-axis', true)
        .call(yAxis);

      /* -- Format Axes-- */
      svg.selectAll('.primary-axis')
        .selectAll('path')
        .remove();

      svg.selectAll('.primary-axis')
        .selectAll('g')
        .selectAll('line')
        .remove();

      svg.selectAll('.primary-axis')
        .selectAll('g')
        .selectAll('text')
        .attr('fill', COLOR_STYLES.graph)
        .attr('dy', '.2em')
        .attr('style', 'font-size: 1.4em;');

      svg.selectAll('g.y-axis g.tick') // Remove axes conflicting with target axes
        .each(function (d) {
          if ((d > bgTarget.low - 10 && d < bgTarget.low + 10)
            || (d > bgTarget.high - 10 && d < bgTarget.high + 10)) {
            d3.select(this).remove();
          }
        });

      svg.selectAll('.target-axis') // Target Axes
        .selectAll('path')
        .remove();

      svg.selectAll('.target-axis') // Target Axes
        .selectAll('g')
        .selectAll('line')
        .remove();

      svg.selectAll('.target-axis') // Target Axes
        .selectAll('g')
        .selectAll('text')
        .attr('fill', COLOR_STYLES.bloodGlucoseHighlightText)
        .attr('dy', '.2em')
        .attr('style', 'font-size: 1.4em;');

      /* -- Horizontal graph lines -- */
      svg.selectAll('g.y-axis g.tick')
        .append('line')
        .classed('grid-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
        .attr('y2', 0)
        .attr('stroke', COLOR_STYLES.graph);

      svg.selectAll('g.target-axis g.tick') // Target grid lines
        .append('line')
        .classed('grid-target-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
        .attr('y2', 0)
        .attr('stroke', COLOR_STYLES.bloodGlucoseHighlightText)
        .attr('stroke-dasharray', '10,10');

      const medianBGLine = d3.line()
        .x((d: any) => xScale(d.time))
        .y((d: any) => yScale(d.value))
        .curve(d3.curveCardinal);

      const bgPercentileArea = d3.area()
        .x((d: any) => xScale(d.time))
        .y0((d: any) => yScale(d.high))
        .y1((d: any) => yScale(d.low))
        .curve(d3.curveCardinal);

      // 95% area
      svg.append('path')
        .classed('ambulatory', true)
        .attr('id', 'ninety-five-percentile-bg-line')
        .attr('opacity', '.5')
        .attr('fill', COLOR_STYLES.bloodGlucoseArea)
        .attr('d', bgPercentileArea(percentileBG.ninetyFive));

      // 50% area
      svg.append('path')
        .classed('ambulatory', true)
        .attr('id', 'fifty-percentile-bg-line')
        .attr('opacity', '.5')
        .attr('fill', COLOR_STYLES.bloodGlucoseArea)
        .attr('d', bgPercentileArea(percentileBG.fifty));

      svg.append('path')
        .classed('ambulatory', true)
        .attr('id', 'median-bg-line')
        .attr('d', medianBGLine(percentileBG.median))
        .attr('stroke', COLOR_STYLES.bloodGlucose)
        .attr('stroke-width', '2')
        .attr('fill', 'none');

      this.isLoading = false;
    }, err => {
      // TODO: NO CGM Integration error
      this.isLoading = false;
      this.isVisible.cgmConnection = false;
      console.log(err);
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    if (this.resizing.currentInnerWidth !== window.innerWidth) {
      this.resizing.isResizing = true;
      if (this.resizing.hasOwnProperty('timeOut')) {
        clearTimeout(this.resizing.timeOut);
      }

      // Create timeout for 300ms
      this.resizing.timeOut = setTimeout(() => {
        if (this.resizing.currentInnerWidth === window.innerWidth) {
          this.resizing.isResizing = false;
        } else {
          this.resizing.currentInnerWidth = window.innerWidth;
          this.resizing.isResizing = false;
          this.drawGraph();
        }
        delete this.resizing.timeOut;
      }, 300);
    }
  }
}
