import { Routes, CanActivate } from '@angular/router';
import { AuthGuard } from './auth.guard';

import { SignInComponent } from './components/sign-in/sign-in.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PatientsComponent } from './components/sub/patients/patients.component';
import { PatientComponent } from './components/sub/patient/patient.component';
import { MyAccountComponent } from './components/sub/my-account/my-account.component';
import { CompleteComponent } from './components/sub/complete/complete.component';
import { MyOrganizationComponent } from './components/sub/my-organization/my-organization.component';
import { GroupComponent } from './components/sub/group/group.component';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: SignInComponent
  },
  {
    path: 'dashboard',
    component: NavigationComponent,
    canActivate: [ AuthGuard ],
    children: [
      { path: '', component: PatientsComponent },
      { path: 'patients', component: PatientsComponent },
      { path: 'patients/:id', component: PatientComponent },
      { path: 'group/:id', component: GroupComponent },
      { path: 'complete', component: CompleteComponent },
      { path: 'account', component: MyAccountComponent },
      { path: 'organization', component: MyOrganizationComponent },
      { path: '**', redirectTo: '' }
    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard' // TODO: requires testing
  }
];
