import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, AuthErrors } from '../../services/auth.service';
import { ClientHttpsService } from '../../services/client-https.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  public errors: AuthErrors;
  public isLoading = false;
  public continueEmail = '';
  public isVisible = {
    signIn: true,
    confirm: false,
    errors: {
      passwordNoMatch: false,
      passwordTooShort: false
    }
  };
  public userCredentials = {
    email: '',
    password: '',
    newPassword: '',
    newPasswordConfirm: ''
  };

  constructor(private auth: AuthService, private router: Router, private client: ClientHttpsService) { }

  ngOnInit() {
    // attempts to get current session
    this.auth.getAccessToken().subscribe(() => {
      this.client.getProviderData().subscribe(val => {
        this.continueEmail = val.email;
      });
    }, () => {});
  }

  public checkForm() {
    // resets all errors
    for (const error in this.isVisible.errors) {
      this.isVisible.errors[error] = false;
    }

    // Checks password
    if (this.userCredentials.newPassword.length !== 0 && this.userCredentials.newPasswordConfirm.length !== 0) {
      if (this.userCredentials.newPassword !== this.userCredentials.newPasswordConfirm) {
        this.isVisible.errors.passwordNoMatch = true;
      }
      if (this.userCredentials.newPassword.length < 8) {
        this.isVisible.errors.passwordTooShort = true;
      }
    } else {
    }
  }

  // getter for button disabling
  get isValidForm() {
    for (const error in this.isVisible.errors) {
      if (this.isVisible.errors[error]) return false;
    }

    if (this.userCredentials.email.length === 0) {
      return false;
    } else if (this.userCredentials.password.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  public signIn() {
    if (this.isValidForm) {
      this.isLoading = true;

      for (const error in this.errors) {
        this.errors[error] = false;
      }

      this.auth.loginUser(this.userCredentials.email, this.userCredentials.password, this.userCredentials.newPassword).subscribe({
        next: res => {
          this.router.navigateByUrl('dashboard');
        },
        error: err => {
          this.isLoading = false;
          this.errors = {
            [err.code]: true
          };

          if (this.errors.NewPasswordRequired) {
            this.isVisible.signIn = false;
            this.isVisible.confirm = true;
          }
        }
      });
    }
  }
}
