import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ClientHttpsService } from '../../services/client-https.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit {
  public isDisplayed = {
    starred: false,
    group: false,
    createGroup: false,
    isLoading: {
      group: false,
      starred: false
    }
  };

  public greeting: string;
  public clickType = {
    popupMenu: false
  };

  public needsToForcePullProviderData = false;
  public groups: Array<any> = [];
  public groupName = '';
  public starredPatients: Array<any> = [];
  public userData = {
    firstName: null,
    lastName: null,
    title: null,
    email: null
  };

  constructor(public router: Router, public client: ClientHttpsService, public auth: AuthService) { }

  ngOnInit() {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.loadData();
      }
    });

    this.loadData();
  }

  public loadData() {
    this.isDisplayed = {
      starred: false,
      group: false,
      createGroup: false,
      isLoading: {
        group: true,
        starred: true
      }
    };

    this.clickType = {
      popupMenu: false
    };

    this.groups = [];
    this.groupName = '';
    this.starredPatients = [];
    this.userData = {
      firstName: null,
      lastName: null,
      title: null,
      email: null
    };

    const now = new Date().getHours();
    if (now < 4) {
      this.greeting = 'Good Evening';
    } else if (now < 12) {
      this.greeting = 'Good Morning';
    } else if (now < 17) {
      this.greeting = 'Good Afternoon';
    } else {
      this.greeting = 'Good Evening';
    }

    this.client.getProviderData(this.needsToForcePullProviderData).subscribe(val => {
      // Checks if user has entered required values
      if (!val.hasOwnProperty('firstName') || !val.hasOwnProperty('lastName') || !val.hasOwnProperty('title')) {
        this.needsToForcePullProviderData = true;
        console.log('missing attributes');
        this.router.navigateByUrl('dashboard/complete');
      } else {
        this.needsToForcePullProviderData = false;
        if (this.router.url === '/dashboard/complete') {
           this.router.navigateByUrl('dashboard');  // prevents users from accessing 'dashboard/complete'
         }

        if (val.starredPatients && val.starredPatients.length !== 0) {
          this.client.getOrganizationData(this.client.orgIdDefault).subscribe(org => {
            if (org.patients.length !== 0) {
              val.starredPatients.forEach(star => {
                for (const item in org.patients) {
                  if (org.patients[item].id === star) {
                    this.starredPatients.push({
                      id: star,
                      firstName: org.patients[item].firstName,
                      lastName: org.patients[item].lastName
                    });
                    break;
                  }
                }
              });

              this.starredPatients.sort((a, b) => {     // Sorts starred patients in alphabetical order (last name)
                if (a.lastName < b.lastName) {
                  return -1;
                } else if (a.lastName > b.lastName) {
                  return 1;
                } else {
                  return 0;
                }
              });
              this.isDisplayed.starred = true;
            }
          }, err => {
            console.log(err);
          });
        }

        if (val.hasOwnProperty('groupNames')) {
          this.groups = val.groupNames;
          this.isDisplayed.group = true;
        }
        this.isDisplayed.isLoading.starred = false;
        this.isDisplayed.isLoading.group = false;
        this.userData.email = val.email;
        this.userData.firstName = val.firstName;
        this.userData.lastName = val.lastName;
        this.userData.title = val.title;
        this.greeting += ` ${this.userData.title} ${this.userData.lastName}`;
      }
    }, err => {
      this.isDisplayed.isLoading.starred = false;
      this.isDisplayed.isLoading.group = false;
      console.log('error loading data');
    });
  }

  public redirectTo(type: string, item): void {
    if (type === 'patient') {
      // this.router.navigate(['/dashboard/patients', item.id]);
      // location.reload();  // Forced reload of page
      this.router.navigateByUrl(`/dashboard/patients/${item.id}`);
    } else if (type === 'group') {
      this.router.navigate(['/dashboard/group', item]);
      //  location.reload();
    }
  }

  public signOut(): void {
    this.client.clearAllCache();
    this.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  public createGroup(): void {
    if (this.groupName.length > 0) {
      this.client.group(this.groupName, 'create-group').subscribe(val => {
        this.isDisplayed.createGroup = false;
        this.router.navigate(['/dashboard/group', this.groupName]);
        this.groupName = '';
        //    location.reload();
      }, err => console.log(err));
    }
  }

  public cancelGroup(): void {
    this.isDisplayed.createGroup = false;
    this.groupName = '';
  }
}
