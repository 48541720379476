import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'refreshTime'
})
export class RefreshTimePipe implements PipeTransform {

  transform(inputDate: Date): string {
    const now = new Date(new Date().setHours(0, 0, 0, 0));
    let timeString = `${inputDate.getHours()}:`;
    if (inputDate.getMinutes() < 10) {
      timeString += `0${inputDate.getMinutes()}`;
    } else {
      timeString += inputDate.getMinutes();
    }

    if (inputDate.getDate() === now.getDate()) {
      return `Today, ${timeString}`;
    }
    if (new Date(inputDate.getTime() + 1000 * 60 * 60 * 24).getDate() === now.getDay()) {
      return `Tomorrow, ${timeString}`;
    }
    if (new Date(inputDate.getTime() - 1000 * 60 * 60 * 24).getDate() === now.getDay()) {
      return `Yesterday, ${timeString}`;
    }
    return `${inputDate.toLocaleDateString} ${timeString}`;
  }

}
