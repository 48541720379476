export const MILLI_TO_DAY = 1000 * 60 * 60 * 24;
export const COLOR_STYLES = {
    bloodGlucose: '#35C4A8',
    bloodGlucoseNoSelect: '#36A7C1',
    bloodGlucoseArea: '#267F61',
    bloodGlucoseHighlightText: '#91C4B3',
    graphText: '#76BCA5',
    graph: '#4D685F',
    insulin: '#FF9B54',
    exercise: '#FF5454',
    weight: '#C4358B',
    food: {
        carbohydrate: '#F354FF',
        fat: '#FFFF54',
        protein: '#54EDFF'
    }
};

export const GRAPH_HEIGHTS = {
    svg: 550,
    main: 400
};

 // TODO: dummy values
export const BLOOD_GLUCOSE_TARGET = {
    high: 180,
    low: 70
};

export const CAL_PER_NUT_GRAM = {
    protein: 4,
    carbohydrate: 4,
    fat: 9
};