import { Component, OnInit } from '@angular/core';
import { ClientHttpsService } from '../../../services/client-https.service';
import { MemberTypePipe } from '../../../pipes/member-type.pipe';

export interface Organization {
  name: string;
  alias: string;
}

@Component({
  selector: 'app-my-organization',
  templateUrl: './my-organization.component.html',
  styleUrls: ['./my-organization.component.css']
})
export class MyOrganizationComponent implements OnInit {
  public organizations: any = [];
  public organizationData: Organization = {
    name: null,
    alias: null
  };

  constructor(private client: ClientHttpsService) { }

  ngOnInit() {
    this.client.getProviderData().subscribe(val => {
      this.organizations = val.organizations;
      this.client.getOrganizationData(this.client.orgIdDefault)
        .subscribe(v => {
          this.organizationData.name = v.organizationName;
          this.organizationData.alias = v.organizationAlias;
        }, err => {
          console.log(err);
        });
    });
  }

  public changeOrganization() {

  }

  // TODO: Change organization user status
}
