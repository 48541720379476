import { Component, OnInit, OnChanges, ViewChild, ElementRef, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ClientHttpsService } from '../../../../services/client-https.service';
import { GRAPH_HEIGHTS, MILLI_TO_DAY, COLOR_STYLES, BLOOD_GLUCOSE_TARGET } from '../config';
import * as d3 from 'd3';
import { Observable } from 'rxjs';

interface Resizing {
  isResizing: boolean;
  currentInnerWidth?: number;
  timeOut?: NodeJS.Timer;
}

@Component({
  selector: 'app-spaghetti-graph',
  templateUrl: './spaghetti-graph.component.html',
  styleUrls: ['./spaghetti-graph.component.css', '../patient.component.css']
})
export class SpaghettiGraphComponent implements OnChanges {
  @Input() baseDate: Date;
  @Input() patientId: string;
  @Input() isDataLoaded: boolean;
  @Output() baseDateChangedEvent = new EventEmitter<Date>();
  @ViewChild('graph', { static: true }) graphWidth: ElementRef;

  public resizing: Resizing;
  public timeRange = 7;
  public startDate: Date;
  public endDate: Date;
  public isLoading: boolean;

  set graphTime(date: string) {
    const fDate = new Date(date).setHours(0, 0, 0, 0);
    this.startDate = new Date(fDate - (this.timeRange * MILLI_TO_DAY));
    this.endDate = new Date(fDate);
    this.drawGraph();
  }

  get graphTime() {
    return this.endDate.toISOString();
  }

  constructor(public client: ClientHttpsService) { }

  ngOnChanges() {
    this.resizing = { isResizing: false };
    this.isLoading = true;
    this.graphTime = this.baseDate.toISOString();

    d3.select('#spaghetti-graph')
      .attr('height', GRAPH_HEIGHTS.main)
      .attr('width', this.graphWidth.nativeElement.offsetWidth);

    if (this.isDataLoaded) {
      this.drawGraph();
    }
  }

  private get dataObservable(): Observable<any> {
    return this.client.getClientBGTimeframeData(
      this.client.orgIdDefault,
      this.patientId,
      this.startDate.toISOString(),
      this.endDate.toISOString(),
    );
  }

  public get isLoadingBinary() {
    if (this.isLoading) {
      return 0;
    } else {
      return 1;
    }
  }

  /* ===== DRAW SPAGHETTI GRAPH ===== */
  public drawGraph() {
    const baseDateChangedEvent = this.baseDateChangedEvent;
    const svg = d3.select('svg#spaghetti-graph');
    const bgTarget = BLOOD_GLUCOSE_TARGET; // for scoping purposes
    const yRange = {  // default and minimum y range
      high: 200,
      low: 50
    };

    this.isLoading = true;

    /* -- remove preexisting elements -- */
    svg.select('text#hover-text')
      .remove();

    svg.selectAll('.spaghetti-line')
      .remove();

    svg.selectAll('g.primary-axis')
      .remove();

    svg.selectAll('g.target-axis')
      .remove();

    this.dataObservable.subscribe(val => {
      let bgData = val.bgData;

      if (val.isCGM && bgData.length > 0) {
        bgData = bgData.sort(
          (a: any, b: any) =>
            +new Date(a.systemTime.replace('T', 'UTC')) - +new Date(b.systemTime.replace('T', 'UTC'))
        );
      } else {
        bgData = bgData.sort((a: any, b: any) => +new Date(a.time) - +new Date(b.time));
      }

      const nestedBGData = d3.nest()
        .key((d: any) => {
          if (val.isCGM) {
            return new Date(new Date(d.systemTime.replace('T', 'UTC')).setHours(0, 0, 0, 0)).getTime().toString();
          } else {
            return new Date(new Date(d.time).setHours(0, 0, 0, 0)).getTime().toString();
          }
        })
        .entries(bgData);

      // determine high and low y range
      const bgSetMax = d3.max(bgData, (d: any) => Number(d.value)) + 10;
      const bgSetMin = d3.min(bgData, (d: any) => Number(d.value)) - 10;
      yRange.high = bgSetMax > yRange.high ? bgSetMax : yRange.high;
      yRange.low = bgSetMin > yRange.low ? bgSetMin : yRange.low;

      const xScale = d3.scaleTime()
        .domain([new Date().setHours(0, 0, 0, 0), new Date(new Date().setHours(0, 0, 0, 0) + MILLI_TO_DAY)]) // Time frame of one day
        .range([75, this.graphWidth.nativeElement.offsetWidth - 75]);

      const yScale = d3.scaleLinear()
        .domain([yRange.low, yRange.high])
        .range([GRAPH_HEIGHTS.main - 50, 0 + 30]);

      const xAxis = d3.axisBottom(xScale)
        .ticks(5)
        .tickFormat((d: any) => d.getHours() + ':00');

      const yAxisTarget = d3.axisLeft(yScale)
        .tickValues([bgTarget.low, bgTarget.high]);

      const yAxis = d3.axisLeft(yScale)
        .ticks(7);

      const cgmLine = d3.line()
        .x((d: any) => {
          const date = new Date(d.systemTime.replace('T', 'UTC'));
          const todayDate = new Date();
          todayDate.setHours(date.getHours());
          todayDate.setMinutes(date.getMinutes());
          todayDate.setSeconds(date.getSeconds());
          return xScale(todayDate);
        })
        .y((d: any) => yScale(d.value))
        .curve(d3.curveCardinal);

      const manualLine = d3.line()
        .x((d: any) => {
          const date = new Date(d.time);
          const todayDate = new Date();
          todayDate.setHours(date.getHours());
          todayDate.setMinutes(date.getMinutes());
          todayDate.setSeconds(date.getSeconds());
          return xScale(todayDate);
        })
        .y((d: any) => yScale(d.value));

      /* -- Draws target y-axis ticks -- */
      svg.append('g')
        .attr('transform', 'translate(75, 0)')
        .classed('target-axis', true)
        .call(yAxisTarget);

      /* -- Draws x-axis ticks -- */
      svg.append('g')
        .attr('transform', `translate(0, ${GRAPH_HEIGHTS.main - 35})`)
        .classed('primary-axis', true)
        .classed('x-axis', true)
        .call(xAxis);

      /* -- Draw y-axis ticks -- */
      svg.append('g')
        .attr('transform', 'translate(75, 0)')
        .classed('primary-axis', true)
        .classed('y-axis', true)
        .call(yAxis);

      /* -- Format Axes-- */
      svg.selectAll('.primary-axis')
        .selectAll('path')
        .remove();

      svg.selectAll('.primary-axis')
        .selectAll('g')
        .selectAll('line')
        .remove();

      svg.selectAll('.primary-axis')
        .selectAll('g')
        .selectAll('text')
        .attr('fill', COLOR_STYLES.graph)
        .attr('dy', '.2em')
        .attr('style', 'font-size: 1.4em;');

      svg.selectAll('g.y-axis g.tick') // Remove axes conflicting with target axes
        .each(function (d: any) {
          if ((d > bgTarget.low - 10 && d < bgTarget.low + 10)
            || (d > bgTarget.high - 10 && d < bgTarget.high + 10)) {
            d3.select(this).remove();
          }
        });

      svg.selectAll('.target-axis') // Target Axes
        .selectAll('path')
        .remove();

      svg.selectAll('.target-axis') // Target Axes
        .selectAll('g')
        .selectAll('line')
        .remove();

      svg.selectAll('.target-axis') // Target Axes
        .selectAll('g')
        .selectAll('text')
        .attr('fill', COLOR_STYLES.bloodGlucoseHighlightText)
        .attr('dy', '.2em')
        .attr('style', 'font-size: 1.4em;');

      /* -- Horizontal graph lines -- */
      svg.selectAll('g.y-axis g.tick')
        .append('line')
        .classed('grid-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
        .attr('y2', 0)
        .attr('stroke', COLOR_STYLES.graph);

      svg.selectAll('g.target-axis g.tick') // Target grid lines
        .append('line')
        .classed('grid-target-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', this.graphWidth.nativeElement.offsetWidth - (2 * 75))
        .attr('y2', 0)
        .attr('stroke', COLOR_STYLES.bloodGlucoseHighlightText)
        .attr('stroke-dasharray', '10,10');

      /* -- Plots each day's data -- */
      const noSelectStrokeWidth = '3px';
      const selectStrokeWidth = '6px';

      if (val.isCGM) {
        /* -- Graph CGM lines -- */
        let cgmPaths = svg.selectAll('g.cgm-spaghetti-line')
          .data(nestedBGData)
          .each(function () { (this as any).parentElement.appendChild(this); });

        cgmPaths.exit().remove();

        cgmPaths = cgmPaths.enter()
          .append('g')
          .classed('spaghetti-line', true)
          .classed('cgm-spaghetti-line', true)
          .classed('selectable', true)
          .append('path')
          .on('mouseover', function () {
            d3.select(this)
              .transition()
              .duration(100)
              .attr('stroke-width', selectStrokeWidth);
          })
          .on('mouseout', function () {
            if (val.isCGM) {
              d3.select(this)
                .transition()
                .attr('stroke-width', noSelectStrokeWidth);
            }
          })
          .attr('stroke', (d: any) => {
            if (d.key === this.baseDate.getTime()) {
              return COLOR_STYLES.bloodGlucose;
            } else {
              return COLOR_STYLES.bloodGlucoseNoSelect;
            }
          })
          .attr('stroke-width', noSelectStrokeWidth)
          .attr('fill', 'none')
          .attr('stroke-opacity', '0');

        svg.selectAll('g.cgm-spaghetti-line path')
          .transition()
          .duration(100)
          .attr('d', (d: any) => cgmLine(d.values))
          .transition()
          .delay((d: any, i: number) => i * 50)
          .attr('stroke-opacity', '1');
      } else {
        nestedBGData.forEach((day: any) => {
          svg.append('g')
            .classed('spaghetti-line', true)
            .classed('selectable', true)
            .attr('id', `spaghetti${day.key}`); // identifier for specific day

          /* -- Graphing path between blood glucose points -- */
          svg.select(`g#spaghetti${day.key}`)
            .selectAll('path')
            .remove();

          svg.select(`#spaghetti${day.key}`)
            .append('path')
            .attr('stroke', () => {
              if (day.key === this.baseDate.getTime()) {
                return COLOR_STYLES.bloodGlucose;
              } else {
                return COLOR_STYLES.bloodGlucoseNoSelect;
              }
            })
            .attr('stroke-width', noSelectStrokeWidth)
            .attr('fill', 'none')
            .attr('d', manualLine(day.values));

          /* -- Draws blood glucose dots -- */
          const spagSelection = svg.select(`g#spaghetti${day.key}`).selectAll('bg-spaghetti-dot')
            .data(day.values)
            .attr('transform', function (d: any) {
              const date = new Date(d.time);
              const todayDate = new Date();
              todayDate.setHours(date.getHours());
              todayDate.setMinutes(date.getMinutes());
              todayDate.setSeconds(date.getSeconds());
              return `translate(${xScale(todayDate)}, ${yScale(d.value)})`;
            });

          spagSelection.enter()
            .append('g')
            .classed('bg-spaghetti-dot', true)
            /*.on('mouseover', function(d) {
              d3.select(this)
                .append('text')
                  .text(d.value + ' mg/dL')
                  .attr('style', 'text-shadow: black 0 0 5px')
                  .attr('fill', 'white')
                  .attr('text-anchor', 'middle')
                  .attr('y', '-15px');
            })
            .on('mouseout', function(d) {
              d3.select(this)
                .selectAll('text')
                  .remove();
            })*/
            .attr('transform', (d: any) => {
              const date = new Date(d.time);
              const todayDate = new Date();
              todayDate.setHours(date.getHours());
              todayDate.setMinutes(date.getMinutes());
              todayDate.setSeconds(date.getSeconds());
              return `translate(${xScale(todayDate)}, ${yScale(d.value)})`;
            })
            // Dots increase in radius on mouse hover
            .append('circle')
            .attr('r', 4)
            .attr('fill', () => {
              if (+day.key === this.baseDate.getTime()) {
                return COLOR_STYLES.bloodGlucose;
              } else {
                return COLOR_STYLES.bloodGlucoseNoSelect;
              }
            })
            .on('mouseover', function (d) {
              d3.select(this)
                .transition()
                .attr('r', '6');
            })
            .on('mouseout', function (d) {
              d3.select(this)
                .transition()
                .attr('r', '4');
            });

          spagSelection.exit()
            .remove();

        });
      }

      // .on functions for spaghetti lines
      svg.selectAll('g.spaghetti-line')
        .on('mouseover', function (d: any) {
          d3.select(this).raise();    // moves selection to front

          if (!val.isCGM) {
            d3.select(this)
              .selectAll('path')
              .transition()
              .duration(100)
              .attr('stroke-width', '5px');

            d3.select(this)
              .selectAll('circle')
              .transition()
              .duration(100)
              .attr('r', '6');
          }

          svg.append('text')
            .attr('id', 'hover-text')
            .attr('text-anchor', 'middle')
            .attr('x', d3.mouse(this as any)[0])
            .attr('y', d3.mouse(this as any)[1] - 20)
            .attr('fill', 'white')
            .text(() => {
              let date: number;
              if (d) {
                date = +d.key;
              } else {
                date = Number(d3.select(this).attr('id').slice(9));
              }
              return new Date(date).toLocaleDateString();
            });
        })
        .on('mouseout', function () {
          if (!val.isCGM) {
            d3.select(this)
              .selectAll('path')
              .transition()
              .attr('stroke-width', noSelectStrokeWidth);

            d3.select(this)
              .selectAll('circle')
              .transition()
              .attr('r', '4');
          }

          svg.select('text#hover-text')
            .remove();
        })
        .on('click', function (d: any) {
          // Clicking on a line will change the graph for daily;
          let date;
          if (d) {
            date = +d.key;
            d3.selectAll('.spaghetti-line path')
              .attr('stroke', COLOR_STYLES.bloodGlucoseNoSelect);

            d3.select(this)
              .select('path')
              .attr('stroke', COLOR_STYLES.bloodGlucose);
          } else {
            date = Number(d3.select(this).attr('id').slice(9));
            d3.selectAll('.spaghetti-line path')
              .attr('stroke', COLOR_STYLES.bloodGlucoseNoSelect);

            d3.selectAll('.bg-spaghetti-dot circle')
              .attr('fill', COLOR_STYLES.bloodGlucoseNoSelect);

            d3.select(this)
              .select('path')
              .attr('stroke', COLOR_STYLES.bloodGlucose);

            d3.select(this)
              .selectAll('circle')
              .attr('fill', COLOR_STYLES.bloodGlucose);
          }

          baseDateChangedEvent.emit(new Date(new Date(date).setHours(0, 0, 0, 0)));
        })
        .on('mousemove', function () {
          // text follows cursor
          svg.select('text#hover-text')
            .attr('x', d3.mouse(this as any)[0])
            .attr('y', d3.mouse(this as any)[1] - 20);
        });

        // Bring all graphed elements to front
        d3.selectAll('.spaghetti-line')
          .each(function () {
            (this as any).parentElement.appendChild(this);
          });

        this.isLoading = false;
      }, (err: any) => {
        this.isLoading = false;
        console.log(err);
      },
    );
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    if (this.resizing.currentInnerWidth !== window.innerWidth) {
      this.resizing.isResizing = true;
      if (this.resizing.hasOwnProperty('timeOut')) {
        clearTimeout(this.resizing.timeOut);
      }

      // Create timeout for 300ms
      this.resizing.timeOut = setTimeout(() => {
        if (this.resizing.currentInnerWidth === window.innerWidth) {
          this.resizing.isResizing = false;
        } else {
          this.resizing.currentInnerWidth = window.innerWidth;
          this.resizing.isResizing = false;
          this.drawGraph();
        }
        delete this.resizing.timeOut;
      }, 300);
    }
  }
}
