import { Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const POOL_DATA = {
  UserPoolId: environment.userPoolId,
  ClientId: environment.clientId
};

export interface AuthErrors {
  NewPasswordRequired?: boolean;
  PasswordResetRequiredException?: boolean;
  UserNotFoundException?: boolean;
  UserNotConfirmedException?: boolean;
  NotAuthorizedException?: boolean;
  InvalidPasswordException?: boolean;
}

@Injectable()
export class AuthService {
  private userPool: CognitoUserPool;
  private cognitoUser: CognitoUser;
  private email: string;
  private password: string;
  private token: string;

  constructor() {
    this.userPool = new CognitoUserPool(POOL_DATA);
    try {
      this.cognitoUser = this.userPool.getCurrentUser();
    } catch (err) {
      console.log(err);
    }
  }

  private getUserPool() {
    if (this.userPool) {
      return this.userPool;
    } else {
      return new CognitoUserPool(POOL_DATA);
    }
  }

  public signOut(): void {
    this.email = null;
    this.password = null;
    if (this.cognitoUser != null) {
      this.cognitoUser.signOut();
      this.cognitoUser = null;
    }
  }

  // Attempts to get current Cognito user
  public getCurrentUser() {
    if (this.cognitoUser) {
      return this.cognitoUser;
    } else {
      try {
        this.cognitoUser = this.userPool.getCurrentUser();
        return this.cognitoUser;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
  }

  // returns an observable
  // NotAuthorizedException
  public getAccessToken(): Observable<any> {
    return Observable.create(observer => {
      if (this.getCurrentUser() !== null) {
        this.getCurrentUser().getSession((err, session) => {
          if (err) {
            observer.error(err);
          } else {
            if (session.isValid()) {
              observer.next(session.getAccessToken().getJwtToken());
              observer.complete();
            } else {
              observer.error('failed getting session');
            }
          }
        });
      } else {
        observer.error('user does not exist');
      }
    });
  }


  /*public registerUser(email: string, password: string, name: { given: string, family: string }): Observable<any> {
    let attributes = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email
      }),
      new CognitoUserAttribute({
        Name: 'given_name',
        Value: name.given
      }),
      new CognitoUserAttribute({
        Name: 'family_name',
        Value: name.family
      })
    ];

    return Observable.create(observer => {
      this.userPool.signUp(email, password, attributes, null, (err, result) => {
        if (err) {
          observer.error(err);
        } else {
          this.cognitoUser = result.user;
          this.email = email;
          this.password = password;
          observer.next();
        }
      });
    });
  }*/

  public loginUser(email: string, password: string, newPassword?: string): Observable<any> {
    this.cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool
    });

    return Observable.create(observer => {
      this.cognitoUser.authenticateUser(new AuthenticationDetails({
        Username: email,
        Password: password
      }), {
          onSuccess: () => {
            observer.next();
          },
          onFailure: err => {
            observer.error(err);
          },
          newPasswordRequired: () => {
            if (newPassword !== '') {
              this.cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
                onSuccess: () => {
                  observer.next();
                  observer.complete();
                },
                onFailure: err => {
                  observer.error(err);
                }
              });

            } else {
              observer.error({ code: 'NewPasswordRequired' });
            }
          }
        });
    });
  }

  /*
  public confirmUser(code) {
    return Observable.create(observer => {
      this.cognitoUser.confirmRegistration(code, true, (err, result) => {
          this.loginUser(this.email, this.password).subscribe(val => {
            observer.next(val);
            observer.complete();
          }, err => {
            observer.error(err);
          });
      });
    });
  }

    public resendConfirmCode(callback) {
      this.cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          callback(err, false);
        } else {
          callback(null, true);
        }
      });
    }

    public resetPassword(newPass, oldPass) {
      return Observable.create(observer => {
        this.cognitoUser.changePassword(oldPass, newPass, (err, result) => {
          if (err) {
            observer.error(err);
          } else {
            console.log('call result: ' + result);
            observer.next();
          }
        });
      });
    } */

    // Initiate forgot password
    public forgotPassword(email) {
      this.cognitoUser = new CognitoUser({
        Username: email,
        Pool: this.getUserPool()
      });

      return Observable.create(observable => {
        this.cognitoUser.forgotPassword({
          onSuccess: res => {
            observable.next(res);
          },
          onFailure: err => {
            observable.error(err);
          }
        });
      });
    }

    public forgotPasswordReset(code, password) {
      return Observable.create(observer => {
        this.cognitoUser.confirmPassword(code, password, {
          onSuccess() {
            observer.next();
          },
          onFailure(err) {
            observer.error(err);
          }
        });
      });
    }
}
